import React, { useState, useEffect } from "react";

import "./textInputWithoutLabel.css";

export default function TextInputWithoutLabel(props: {
  value?: string;
  label?: string;
  onValueChange: Function;
  onSaveBtnClick: Function;
}) {
  const [value, setValue] = useState<string>("");
  const [saveBtnClick, setSaveBtnClick] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  function handleValueChange(value: string) {
    setValue(value);
    props.onValueChange(value);
  }

  function handleSaveBtnClick(value: boolean) {
    setSaveBtnClick(value);
    props.onSaveBtnClick(value);
  }

  return (
    <div id="text-input-main">
      <div id="text-input-container">
        <div id="text-input-text">
          <input
            id="text-input-text-field"
            onChange={(e) => {
              handleValueChange(e.target.value);
            }}
            value={value}
          />
        </div>
        <div
          id="text-input-btn"
          onClick={(e) => {
            handleSaveBtnClick(true);
          }}
        >
          <div id="text-input-btn-text">Salvar</div>
        </div>
      </div>
    </div>
  );
}
