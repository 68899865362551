import React from "react";

import "./footer.css";

import DiscordHotLink from "assets/hotLinks/discord.png";
import PartnersHotLink from "assets/hotLinks/parners.png";
import TermsHotLink from "assets/hotLinks/terms.png";

export default function Footer() {
  return (
    <div id="footer-main">
      <div id="footer-hot-links">
        <a className="footer-hot-link" href="/parceiros" title="Parceiros">
          <img
            className="footer-hot-link-img"
            src={PartnersHotLink}
            alt="Parceiros"
          />
          Parceiros
        </a>
        <a
          className="footer-hot-link"
          href="/termos"
          title="Termos e Diretrizes"
        >
          <img
            className="footer-hot-link-img"
            src={TermsHotLink}
            alt="Termos e Diretrizes"
          />
          Termos e Diretrizes
        </a>
        <a className="footer-hot-link" href="https://discord.gg/caseskins" title="Discord">
          <img
            className="footer-hot-link-img"
            src={DiscordHotLink}
            alt="Discord"
          />
          Discord
        </a>
      </div>
      <div id="footer-legal-info">
        <p id="footer-legal-info-txt">
          caseskins.gg LTDA - Não somos afiliados à Valve Corp. -
          suporte@caseskins.gg
        </p>
      </div>
    </div>
  );
}
