import React, { useContext, useEffect, useState } from "react";

import "./partnerProfile.css";

import TextInput from "components/input/textInput/textInput";
import TextInputLarge from "components/input/textInputLarge/textInputLarge";
import UploadFileBtn from "components/uploadFileBtn/uploadFileBtn";
import { IPartner, PartnerStatus } from "interfaces/IPartner";
import { APICaller, imagePrefix } from "api/APICaller";
import { AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";

export default function PartnerProfile(props: {
  partner: IPartner;
  setPartner: Function;
  onDelete: Function;
}) {
  const [name, setName] = useState<string>("");
  const [profession, setProfession] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [externalUrl, setExternalUrl] = useState<string>("");
  const [coupon, setCoupon] = useState<string>("");
  const [expirationDateStart, setExpirationDateStart] =
    useState<string>("1/1/1999");
  const [expirationDateEnd, setExpirationDateEnd] =
    useState<string>("1/1/1999");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [updatedImage, setUpdatedImage] = useState<boolean>(false);
  const [imageForm, setImageForm] = useState<any>();
  const [image, setImage] = useState<any>();

  const globalContext = useContext(GlobalContext);

  function generateRandomCode(length: number) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  useEffect(() => {
    setName(props.partner.name);
    setProfession(props.partner.profession);
    setDescription(props.partner.description);
    setExternalUrl(props.partner.external_url);

    if (props.partner.id !== "") {
      setCoupon(props.partner.coupon);
    } else {
      setCoupon(generateRandomCode(8));
    }
    setImage(imagePrefix("PARTNERS", props.partner.partner_image));
    setExpirationDateStart(props.partner.expiration_date_start);
    setExpirationDateEnd(props.partner.expiration_date_end);
  }, []);

  useEffect(() => {
    if (isUpdating) {
      updatePartner();
      setIsUpdating(false);
      setUpdatedImage(false);
    }
  }, [isUpdating]);

  function handlePartnerNameChange(value: string) {
    setName(value);
  }

  function handlePartnerProfessionChange(value: string) {
    setProfession(value);
  }

  function handlePartnerDescriptionChange(value: string) {
    setDescription(value);
  }

  function handlePartnerExternalLinkChange(value: string) {
    setExternalUrl(value);
  }

  function handlePartnerCouponChange(value: string) {
    setCoupon(value);
  }

  function handlePartnerExpirationDateStartChange(value: string) {
    setExpirationDateStart(value);
  }

  function handlePartnerExpirationDateEndChange(value: string) {
    setExpirationDateEnd(value);
  }

  function handleSave() {
    props.setPartner({
      ...props.partner,
      name: name,
      profession: profession,
      description: description,
      external_url: externalUrl,
      coupon: coupon,
      expiration_date_start: expirationDateStart,
      expiration_date_end: expirationDateEnd,
    });

    setIsUpdating(true);
  }

  function handleUploadImg(img: any) {
    const form = new FormData();
    form.append("image", img);

    setImage(URL.createObjectURL(img));
    setImageForm(form);

    setUpdatedImage(true);
  }

  function updateImage(partnerId: string, cb?: Function) {
    APICaller.put(`/partners/image/${partnerId}/`, imageForm, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        const updateImageResponse: IPartner = response.data.data;
        props.setPartner(updateImageResponse);
        setImage(imagePrefix("PARTNERS", updateImageResponse.partner_image));

        if (cb) cb();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  function updatePartner() {
    if (props.partner.id === "") {
      APICaller.post("/partners/", props.partner, {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      })
        .then((response: AxiosResponse) => {
          const createResponse: IPartner = response.data.data;
          props.setPartner(createResponse);
          console.log("Parceiro criado com sucesso.");

          if (updatedImage) {
            updateImage(createResponse.id, props.onDelete);
          } else {
            props.onDelete();
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      APICaller.put(`/partners/${props.partner.id}`, props.partner, {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      })
        .then((response: AxiosResponse) => {
          const updateResponse: IPartner = response.data.data;
          props.setPartner(updateResponse);

          if (updatedImage) {
            updateImage(updateResponse.id, props.onDelete);
          } else {
            props.onDelete();
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }

  function handleDeletePartner() {
    if (props.partner && props.partner.id) {
      APICaller.delete(`/partners/${props.partner.id}/`, {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      })
        .then((response: AxiosResponse) => {
          props.onDelete();
          props.setPartner({
            id: "",
            name: "",
            profession: "",
            description: "",
            external_url: "",
            coupon: "",
            partner_image: "",
            expiration_date_start: "1/1/1999",
            expiration_date_end: "1/1/1999",
            status: PartnerStatus.ACTIVE,
          });
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }

  return (
    <div id="partner-profile-main">
      <div id="partner-profile-section-title-container">
        <p id="partner-profile-section-title-text">Dados do perfil</p>
      </div>
      <div id="partner-profile-content-container">
        <div id="partner-profile-content">
          <div id="partner-profile-content-img-container">
            <img id="partner-profile-content-img" src={image} alt="" />
            <div id="partner-profile-content-img-upload-container">
              <div id="partner-profile-content-img-upload">
                <UploadFileBtn
                  parent="-partner-profile"
                  onUploadImg={handleUploadImg}
                />
              </div>
            </div>
          </div>
          <div id="partner-profile-content-info-container">
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Nome"
                onValueChange={handlePartnerNameChange}
                onSaveBtnClick={handleSave}
                value={name}
              />
            </div>
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Profissão"
                onValueChange={handlePartnerProfessionChange}
                onSaveBtnClick={handleSave}
                value={profession}
              />
            </div>
            <div className="partner-profile-content-info-text-input2">
              <TextInputLarge
                label="Descrição"
                onValueChange={handlePartnerDescriptionChange}
                onSaveBtnClick={handleSave}
                value={description}
              />
            </div>
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Link Externo"
                onValueChange={handlePartnerExternalLinkChange}
                onSaveBtnClick={handleSave}
                value={externalUrl}
              />
            </div>
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Cupom"
                onValueChange={handlePartnerCouponChange}
                onSaveBtnClick={handleSave}
                value={coupon}
              />
            </div>
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Válido de"
                onValueChange={handlePartnerExpirationDateStartChange}
                onSaveBtnClick={handleSave}
                value={expirationDateStart}
              />
            </div>
            <div className="partner-profile-content-info-text-input1">
              <TextInput
                label="Válido até"
                onValueChange={handlePartnerExpirationDateEndChange}
                onSaveBtnClick={handleSave}
                value={expirationDateEnd}
              />
            </div>
          </div>
          <div id="partner-profile-content-delete">
            <div
              id="partner-profile-content-delete-btn"
              onClick={handleDeletePartner}
            >
              <p id="partner-profile-content-delete-btn-text">
                DELETAR PARCEIRO
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
