import React, { useEffect } from "react";
import Routes from "routes/routes";
import { initMercadoPago } from "@mercadopago/sdk-react";
import GlobalProvider from "context/globalProvider";

initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY || "");

export default function App() {
  useEffect(() => {
    /* prevent zoom */

    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (
        event.ctrlKey === true &&
        (event.key === "=" ||
          event.key === "+" ||
          event.key === "-" ||
          event.keyCode === 61 ||
          event.keyCode === 107 ||
          event.keyCode === 173 ||
          event.keyCode === 109 ||
          event.keyCode === 187 ||
          event.keyCode === 189)
      ) {
        event.preventDefault();
      }
    });

    window.addEventListener(
      "wheel",
      (event: WheelEvent) => {
        if (event.ctrlKey === true) {
          event.preventDefault();
        }
      },
      { passive: false },
    );
  }, []);

  return (
    <GlobalProvider>
      <Routes></Routes>
    </GlobalProvider>
  );
}
