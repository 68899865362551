import React, { useEffect, useState } from "react";

import "./navBarLevelSelector.css";
import { ILevel } from "interfaces/ILevel";

export default function NavBarDropDown(props: {
  levelList: ILevel[];
  onLevelClick: Function;
}) {
  const [width, setWidth] = useState<number>(0);

  const [hover, setHover] = useState<boolean>(false);

  const [renderDropDown, setRenderDropDown] = useState<boolean>(false);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [position, setPosition] = useState<any>({ positionX: 0, positionY: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setRenderDropDown(false);
  }, [windowDimensions]);

  useEffect(() => {
    const elemento = document.getElementById("nav-bar-dropdown-main");
    if (elemento != null) {
      const rect = elemento.getBoundingClientRect();
      setWidth(rect.width);
      setPosition({
        X: rect.left,
        Y: rect.top + rect.height,
      });
    }
  }, []);

  function handleClick(id: string) {
    props.onLevelClick(id);
    setRenderDropDown(false);
  }

  function handleRenderDropdown() {
    if (renderDropDown == true) {
      setRenderDropDown(false);
    } else {
      setRenderDropDown(true);
    }
  }

  function handleHoverIn() {
    setHover(true);
  }

  function handleHoverOut() {
    setHover(false);
  }

  function backgroundColorController() {
    if (renderDropDown === true) {
      return "#06121E";
    } else {
      if (hover === true) {
        return "#13273B";
      } else {
        return "#101F2E";
      }
    }
  }

  return (
    <>
      <div
        id="nav-bar-dropdown-main"
        onClick={handleRenderDropdown}
        style={{ backgroundColor: backgroundColorController() }}
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
      >
        <p id="nav-bar-dropdown-txt">Niveis</p>
      </div>
      <ul
        id="nav-bar-dropdown-list"
        style={{
          display: renderDropDown ? "flex" : "none",
          top: position.Y,
          left: position.X,
          width: width,
        }}
      >
        {props.levelList.map((level) => {
          return (
            <li
              id="nav-bar-dropdown-list-element"
              key={level.id}
              onClick={() => {
                handleClick(level.id);
              }}
            >
              <p id="nav-bar-dropdown-list-element-text">NÍVEL</p>
              <p id="nav-bar-dropdown-list-element-number-text">
                {level.position}
              </p>
            </li>
          );
        })}
      </ul>
    </>
  );
}
