import React from "react";

import "./termItem.css";

export default function TermItem(props: {
  id: string;
  topic: string;
  content: string;
}) {
  return (
    <section id="term-content-card-main">
      <div id="term-content-card-main-question-container">
        <p id="term-content-card-main-question-text">{props.topic}</p>
      </div>
      <div id="term-content-card-main-content-container">
        <p id="term-content-card-main-content-text">{props.content}</p>
      </div>
    </section>
  );
}
