import React from "react";

import "../page404/page404.css";

import Logo from "assets/logo.png";

export default function ErrorPage(props: { title: string; body: string }) {
  return (
    <div id="page404-main">
      <div id="page404-content">
        <img id="page404-content-logo" src={Logo} alt="" draggable={false} />
        <p id="page404-content-error-txt">{props.title}</p>
        <p id="page404-content-error-txt-info">{props.body}</p>
      </div>
    </div>
  );
}
