import React, { useContext, useEffect, useState } from "react";

import "./userList.css";

import IUser from "interfaces/IUser";

import UserCard from "./userCard/userCard";
import SearchField from "components/searchField/searchField";
import INormalUser from "interfaces/INormalUser";
import NormalUserCard from "./normalUserCard/normalUserCard";

export default function UserList(props: {
  steamUsers: IUser[];
  users: INormalUser[];
  renderUserDetail: Function;
  renderNormalUserDetail: Function;
}) {
  const [filteredSteamUsers, setFilteredSteamUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<INormalUser[]>([]);

  const [searchField, setSearchField] = useState<string>("");

  useEffect(() => {
    setFilteredUsers(props.users);
    setFilteredSteamUsers(props.steamUsers);
  }, [props.users, props.steamUsers]);

  useEffect(() => {
    setFilteredSteamUsers(
      props.steamUsers.filter((su) => su.persona_name.includes(searchField)),
    );

    setFilteredUsers(
      props.users.filter((u) => u.username.includes(searchField)),
    );
  }, [searchField]);

  function handleSteamUserInfoClick(id: number) {
    for (let index = 0; index < props.steamUsers.length; index++) {
      if (props.steamUsers[index].id == id) {
        props.renderUserDetail(props.steamUsers[index]);
      }
    }
  }

  function handleUserInfoClick(id: number) {
    for (let index = 0; index < props.users.length; index++) {
      if (props.users[index].id == id) {
        props.renderNormalUserDetail(props.users[index]);
      }
    }
  }

  function handleSearchFieldChange(value: string) {
    setSearchField(value);
  }

  return (
    <div id="user-list-main">
      <div id="user-list-section-title-container">
        <p id="user-list-section-title-text">Usuários</p>
      </div>
      <div id="user-list-decoration" />
      <div id="user-list-content">
        <div id="user-list-search-user">
          <div id="user-list-search-user-container">
            <SearchField
              placeholder="Insira o nome"
              onValueChange={handleSearchFieldChange}
            />
          </div>
        </div>
        <div id="user-list-all-steamUsers">
          <div id="user-list-labels">
            <div id="user-list-label-name">
              <p className="user-list-text">Nome</p>
            </div>
            <div id="user-list-label-email">
              <p className="user-list-text">Discord</p>
            </div>
            <div id="user-list-steam-link">
              <p className="user-list-text">Perfil</p>
            </div>
          </div>
          <ul id="userlist-users">
            {filteredSteamUsers.map((user) => {
              return (
                <UserCard
                  key={user.id}
                  onMoreInfoClick={handleSteamUserInfoClick}
                  user={user}
                />
              );
            })}
            {filteredUsers.map((user) => {
              return (
                <NormalUserCard
                  key={user.id}
                  onMoreInfoClick={handleUserInfoClick}
                  user={user}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
