export default interface IDate {
  day: number;
  month: number;
  year: number;
}

export function IDateToString(date: IDate): string {
  return `${date.day}/${date.month}/${date.year}`;
}

export function StringToIDate(dateString: string): IDate {
  const dateList = dateString.split("/");

  if (dateList.length == 3) {
    return {
      day: parseInt(dateList[0]),
      month: parseInt(dateList[1]),
      year: parseInt(dateList[2]),
    };
  } else {
    return {
      day: 1,
      month: 1,
      year: 1999,
    };
  }
}

export function MonthToText(month: number) {
  const months = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];
  if (month > 0 && month <= 12) {
    return months[month - 1];
  }
  return months[0];
}
