import React from "react";

import "./faqQuestionAnswerCard.css";

export default function FaqQuestionAnswerCard(props: {
  id: string;
  question: string;
  answer: string;
}) {
  return (
    <section id={props.id} className="faq-question-answer-card-main">
      <div id="faq-question-answer-card-main-question-container">
        <p id="faq-question-answer-card-main-question-text">{props.question}</p>
      </div>
      <div id="faq-question-answer-card-main-answer-container">
        <p id="faq-question-answer-card-main-answer-text">
          {props.answer.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </section>
  );
}
