import React from "react";

import "./marketControlForSaleCard.css";

import AK from "assets/ak.png";
import IItem from "interfaces/IItem";
import { imagePrefix } from "api/APICaller";

export default function MarketControlForSaleCard(props: {
  data: IItem;
  onEditItemClick: Function;
}) {
  function handleEditItem() {
    props.onEditItemClick(props.data.id);
  }

  return (
    <li id="market-control-for-sale-card-main">
      <div id="market-control-for-sale-card-image-container">
        <img
          id="market-control-for-sale-card-item-image"
          src={imagePrefix("MARKET", props.data.item_image)}
          alt=""
          draggable={false}
        />
      </div>
      <div id="market-control-for-sale-card-name">
        <p id="market-control-for-sale-card-name-txt">{props.data.item_name}</p>
      </div>
      <div id="market-control-for-sale-card-price">
        <p id="market-control-for-sale-card-price-txt">R$ {props.data.price}</p>
      </div>
      <div id="market-control-for-sale-card-edit">
        <div id="market-control-for-sale-card-btn" onClick={handleEditItem}>
          <p id="market-control-for-sale-card-btn-txt">Editar</p>
        </div>
      </div>
    </li>
  );
}
