import React, { useEffect, useState } from "react";
import { IPartner } from "interfaces/IPartner";

import "./partnerCard.css";

import Imagem from "../partnerCard/Gesicht.png";
import { imagePrefix } from "api/APICaller";
import IDate, { MonthToText, StringToIDate } from "interfaces/IDate";

export default function PartnerCard(props: {
  partner: IPartner;
  onPartnerDetailClick: Function;
}) {
  function handlePartnerDetailClick() {
    props.onPartnerDetailClick(props.partner);
  }

  const [expirationDate, setExpirationDate] = useState<IDate>({
    day: 0,
    month: 0,
    year: 0,
  });

  useEffect(() => {
    setExpirationDate(StringToIDate(props.partner.expiration_date_end));
  }, []);

  return (
    <li id="partner-card-main" key={props.partner.id}>
      <div id="partner-card-img-container">
        <img
          id="partner-card-img"
          src={imagePrefix("PARTNERS", props.partner.partner_image)}
          alt=""
        />
      </div>
      <div id="partner-card-info">
        <div id="partner-card-info-name">
          <p id="partner-card-info-name-text" className="partner-card-text">
            {props.partner.name}
          </p>
        </div>
        <div id="partner-card-info-code">
          <p id="partner-card-info-code-text" className="partner-card-text">
            {props.partner.coupon}
          </p>
        </div>
        <div id="partner-card-info-validity">
          <p
            id="partner-card-info-validity-label-text"
            className="partner-card-text"
          >
            Validade
          </p>
          <p id="partner-card-info-validity-text" className="partner-card-text">
            {`${expirationDate.day} ${MonthToText(expirationDate.month)} ${expirationDate.year}`}
          </p>
        </div>
      </div>
      <div id="partner-card-info-edit-btn-container">
        <div id="partner-card-info-edit-btn" onClick={handlePartnerDetailClick}>
          <p id="partner-card-info-btn-text" className="partner-card-text">
            Editar
          </p>
        </div>
      </div>
    </li>
  );
}
