import React from "react";

import "./bannerListElement.css";

import trashCan from "assets/icons/trashCan.png";
import IBanner from "interfaces/IBanner";

export default function BannerListElement(props: {
  banner: IBanner;
  position: number;
  onEditBanner: Function;
}) {
  function handleEditBtnClick() {
    props.onEditBanner(props.banner);
  }

  return (
    <li id="banner-list-element-main" key={props.banner.id}>
      <div id="banner-list-element-label">
        <p id="banner-list-element-label-txt">BANNER</p>
        <p id="banner-list-element-label-number-txt">{props.position}</p>
      </div>
      <div id="banner-list-element-btn-container">
        <div id="banner-list-element-btn" onClick={handleEditBtnClick}>
          <p id="banner-list-element-btn-txt">Editar</p>
          <img id="banner-list-element-btn-img" src={trashCan} />
        </div>
      </div>
    </li>
  );
}
