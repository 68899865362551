import { GlobalContext } from 'context/globalProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Exit from "assets/userHeaderMenuDropdownIcons/exit.png";
import Help from "assets/userHeaderMenuDropdownIcons/help.png";
import Account from "assets/userHeaderMenuDropdownIcons/account-circle.png";
import Chevron from "assets/icons/left-arrow.png";
import Ticket from "assets/icons/silverTicket.png";
import Add from "assets/icons/addGreen.png";
import { APICaller } from 'api/APICaller';
import { AxiosResponse } from 'axios';

import './userConnected.css';

export default function UserConnected(props: { renderBuyTicketScreen?: Function }) {

    const [isActive, setIsActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);

    const handleIsActive = () => {
        setIsActive(!isActive);
    }

    const handleBuyTicketButtonClick = () => {
        if (props.renderBuyTicketScreen) {
            props.renderBuyTicketScreen(true);
        }
    };

    const handleProfile = () => {
        navigate("/perfil");
    }

    const handleFAQ = () => {
        navigate("/faq");
    }

    const handleLogoff = () => {
        globalContext?.rmToken();
        navigate("/");
    }

    const UserConnectedEntries = [
        {
            icon: Account,
            name: 'Meu Perfil',
            click: handleProfile,
        },
        {
            icon: Help,
            name: 'Perguntas Frequentes',
            click: handleFAQ,
        },
        {
            icon: Exit,
            name: 'Sair',
            click: handleLogoff,
        }
    ];


  useEffect(() => {
    APICaller.get("/wallets/tickets/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        globalContext?.setTickets(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

    return (
        <div id='user-connected'>
            <div id='user-connected__main'>
                <div id='user-connected__tickets'>
                    <div id='user-connected__tickets-icon'>
                        <img src={Ticket} alt="Ticket"/>
                    </div>
                    <div id='user-connected__tickets-value'>
                        { globalContext?.getTickets() }
                    </div>
                    <div id='user-connected__tickets-buy' onClick={handleBuyTicketButtonClick}>
                        <img src={Add} alt='Adicionar tickets' />
                    </div>
                </div>
               
            </div>
            <div id='user-connected__secondary'>
                <div id='user-connected__profile'>
                    <div id='user-connected__profile-base' onClick={handleIsActive} className={`user-connected__profile-base-${isActive ? 'active' : 'normal'}-borders`}>
                        <div id='user-connected__profile-base-image'>
                            <img src={globalContext?.avatar} alt="Avatar do usuário" id='user-connected__profile-base-image-avatar'/>
                        </div>
                        <div id='user-connected__profile-base-username'>
                            { globalContext?.username}
                        </div>
                        <div id='user-connected__profile-base-chevron' className={`user-connected__profile-base-${isActive ? 'rotated' : 'normal'}-chevron`}>
                            <img src={Chevron} alt='Chevron'/>
                        </div>
                    </div>
                    {
                        isActive &&
                        <div id='user-connected__profile-active'>
                            {
                                UserConnectedEntries.map((entry) => 
                                <div id='user-connected__profile-entry' onClick={entry.click}>
                                    <div id='user-connected__profile-entry-icon'>
                                        <img alt='Ícone' src={ entry.icon }/>
                                    </div>
                                    <div id='user-connected__profile-entry-title'>
                                        { entry.name }
                                    </div>
                                </div>)
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}