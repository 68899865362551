import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./imageSlider.css";

import IBanner from "interfaces/IBanner";
import { APICaller, imagePrefix } from "api/APICaller";
import { AxiosResponse } from "axios";

export default function ImageSlider() {
  // const [productImgs, setProductImgs] = useState<IImage[]>([])
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  useEffect(() => {
    APICaller.get("/banners/")
      .then((response: AxiosResponse) => {
        const data: IBanner[] = response.data.data;
        setBanners(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  return (
    <CarouselProvider
      naturalSlideWidth={1200}
      naturalSlideHeight={350}
      totalSlides={banners.length}
      infinite={true}
      dragEnabled={false}
      className="slide-main"
      currentSlide={currentSlide}
      isPlaying={true}
    >
      <Slider className="slider">
        {banners.map((banner, index) => {
          return (
            <Slide className="img-container" index={index} key={banner.id}>
              <Image
                alt="Imagem do banner"
                src={imagePrefix("BANNERS", banner.filename)}
                hasMasterSpinner={false}
                draggable={false}
                style={{ backgroundSize: "cover" }}
                isBgImage={true}
              />
            </Slide>
          );
        })}
      </Slider>
      <DotGroup id="dot-group" />
    </CarouselProvider>
  );
}
