import React, { useEffect, useState } from "react";

import "./passwordInput.css";

export default function PasswordInput(props: {
  label?: string;
  value?: string;
  onValueChange: Function;
  onKeyDown: Function;
}) {
  const [value, setValue] = useState<string>("");
  const [hide, setHide] = useState<string>("password");
  const [message, setMessage] = useState<string>("MOSTRAR");

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  function handleValueChange(value: string) {
    setValue(value);
    props.onValueChange(value);
  }

  function handleHidePassword() {
    if (hide === "password") {
      setHide("text");
      setMessage("ESCONDER");
    } else {
      setHide("password");
      setMessage("MOSTRAR");
    }
  }

  return (
    <div id="password-input-main">
      <div id="password-input-label-container">
        <p id="password-input-label-txt">{props.label}</p>
      </div>
      <div id="password-input-field-container">
        <div id="password-input-container">
          <input
            id="password-input"
            onChange={(e) => {
              handleValueChange(e.target.value);
            }}
            onKeyDown={(e) => props.onKeyDown(e)}
            value={value}
            type={hide}
          />
        </div>
        <div id="password-input-btn-container" onClick={handleHidePassword}>
          <div id="text-input-full-btn-text2">{message}</div>
        </div>
      </div>
    </div>
  );
}
