import React from "react";

import IHistory from "interfaces/IHistory";
import HistoryEntry from "./historyEntry/historyEntry";

import "./history.css";

export default function History(props: { history: IHistory[] }) {
  return (
    <>
      <div id="history-label">
        <p id="history-label-text-date">DATA</p>
        <p id="history-label-text-type">TIPO</p>
        <p id="history-label-text-tickets">DESCRIÇÃO</p>
        <p id="history-label-text-value">VALOR</p>
      </div>
      <ul id="history-list">
        {props.history.length > 0 &&
          props.history.map((entry) => <HistoryEntry entry={entry} />)}
      </ul>
    </>
  );
}
