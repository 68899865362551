import React from "react";

import "./progressCard.css";
import { IProgress } from "interfaces/IProgress";

export default function ProgressCard(props: { progress: IProgress }) {
  return (
    <div id="progress-card">
      <div id="progress-card-title">Sorteio {props.progress.name}</div>
      <div id="progress-card-tickets-spent">
        Tickets investidos: {props.progress.tickets_invested}
      </div>
      <div id="progress-card-percentage">
        Porcentagem de chance: {(props.progress.percentage * 100).toFixed(2)} %
      </div>
    </div>
  );
}
