import React, { useContext, useEffect, useState } from "react";

import "./marketControlForSaleAddItem.css";

import TextInputWithoutSave from "components/input/textInputWithoutSave/textInputWithoutSave";
import IItem, { ItemCategory, ItemStatus } from "interfaces/IItem";
import CloseCross from "assets/icons/closeCross.png";
import UploadFileBtn from "components/uploadFileBtn/uploadFileBtn";
import Dropdown from "./components/dropdown/dropdown";

import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";

export default function MarketControlForSaleAddItem(props: {
  onAddForSaleItemModalExitClick: Function;
}) {
  const [marketItem, setMarketItem] = useState<IItem>({
    id: "",
    item_name: "",
    category: ItemCategory.SKIN,
    item_image: "",
    item_float: 0,
    price: 0,
    inspection_url: "",
    status: ItemStatus.FOR_SALE,
  });

  const [itemName, setItemName] = useState<string>("");
  const [category, setCategory] = useState<ItemCategory>(ItemCategory.SKIN);
  const [itemFloat, setItemFloat] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [inspectionUrl, setInspectionUrl] = useState<string>("");
  const [imageForm, setImageForm] = useState<any>();
  const [image, setImage] = useState<any>();
  const [imageName, setImageName] = useState<string>("");

  const globalContext = useContext(GlobalContext);

  useEffect(() => {}, [marketItem]);

  function handleExitBtnClick() {
    props.onAddForSaleItemModalExitClick(false);
  }

  function handleItemNameChange(value: string) {
    setItemName(value);
  }

  function handleCategoryChange(value: ItemCategory) {
    setCategory(value);
  }

  function handleItemFloatChange(value: number) {
    setItemFloat(value);
  }

  function handlePriceChange(value: number) {
    setPrice(value);
  }

  function handleInspectionUrlChange(value: string) {
    setInspectionUrl(value);
  }

  function handleSaveItem() {
    const newMarketItem = {
      item_name: itemName,
      category: category,
      item_float: itemFloat,
      item_image: imageName,
      price: price,
      inspection_url: inspectionUrl,
      status: ItemStatus.FOR_SALE,
    };

    APICaller.post("/market/", newMarketItem, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IItem = response.data.data;
        setMarketItem(data);
        console.log(response.data.message);

        APICaller.put(`/market/image/${data.id}/`, imageForm, {
          headers: {
            Authorization: `Bearer ${globalContext?.getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response: AxiosResponse) => {
            const data: IItem = response.data.data;
            setMarketItem(data);
            props.onAddForSaleItemModalExitClick(false);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  function handleUploadImg(img: any) {
    const form = new FormData();
    form.append("image", img);
    setImageName(img.name);
    setImage(URL.createObjectURL(img));
    setImageForm(form);
  }
  return (
    <div id="market-control-for-sale-add-item-main">
      <div id="market-control-for-sale-add-item-container">
        <div id="market-control-for-sale-add-item-exit">
          <img
            id="join-raffle-modal-exit-btn-img"
            alt="Fechar"
            src={CloseCross}
            onClick={handleExitBtnClick}
            draggable={false}
          />
        </div>
        <div id="market-control-for-sale-add-item-img-container">
          <img
            id="market-control-for-sale-add-item-img"
            src={image}
            alt="Imagem do item"
            draggable={false}
          />
        </div>
        <div id="market-control-for-sale-add-item-add-container">
          <div id="market-control-for-sale-add-item-add-image-upload">
            <UploadFileBtn
              key={"market-add-item"}
              onUploadImg={handleUploadImg}
            />
          </div>
          <div id="market-control-for-sale-add-item-add-dropdown-container">
            <Dropdown
              selectedCategory={category}
              setCategory={handleCategoryChange}
            />
          </div>
          <div className="market-control-for-sale-add-item-add-text-input-container">
            <TextInputWithoutSave
              label="Nome do item"
              value={marketItem?.item_name}
              onValueChange={handleItemNameChange}
              onKeyDown={() => {}}
            />
          </div>
          <div className="market-control-for-sale-add-item-add-text-input-container">
            <TextInputWithoutSave
              label="Preço"
              value={marketItem?.price}
              onValueChange={handlePriceChange}
              onKeyDown={() => {}}
            />
          </div>
          {category !== ItemCategory.AGENTE && (
            <div className="market-control-for-sale-add-item-add-text-input-container">
              <TextInputWithoutSave
                label="Float"
                value={marketItem?.item_float}
                onValueChange={handleItemFloatChange}
                onKeyDown={() => {}}
              />
            </div>
          )}
          <div className="market-control-for-sale-add-item-add-text-input-container">
            <TextInputWithoutSave
              label="Link de inspeção"
              value={marketItem?.inspection_url}
              onValueChange={handleInspectionUrlChange}
              onKeyDown={() => {}}
            />
          </div>
        </div>
        <div id="market-control-for-sale-add-item-btn-container">
          <div
            id="market-control-for-sale-add-item-btn-save"
            onClick={handleSaveItem}
          >
            <p className="market-control-for-sale-add-item-btn-txt">SALVAR</p>
          </div>
        </div>
      </div>
    </div>
  );
}
