import React, { useContext, useState } from 'react';

import Shield from "assets/icons/adminShield.png";
import Chevron from "assets/icons/left-arrow.png";
import Exit from "assets/userHeaderMenuDropdownIcons/exit.png";
import Settings from "assets/userHeaderMenuDropdownIcons/settings.png";

import './adminConnected.css'
import { GlobalContext } from 'context/globalProvider';
import { useNavigate } from 'react-router-dom';

export default function AdminConnected() {

    const [isActive, setIsActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const authProvider = useContext(GlobalContext);

    const handleIsActive = () => {
        setIsActive(!isActive);
    }

    const handleSettings = () => {
        navigate('/admin/controle');
    }

    const handleLogoff = () => {
        authProvider?.rmToken();
        navigate("/");
    }

    const AdminConnectedEntries = [
        {
            
            icon: Settings,
            name: 'Painel de controle',
            click: handleSettings
        },
        {
            icon: Exit,
            name: 'Sair',
            click: handleLogoff
        }
        
    ];

    return (
        <div id='admin-connected__main'>
            <div id='admin-connected__base' onClick={handleIsActive} className={`admin-connected__base-${isActive ? 'active' : 'normal'}-borders`}>
                <div id='admin-connected__icon'>
                    <img alt='Escudo' src={Shield}/>
                </div>
                <div id='admin-connected__title'>
                    Administrador
                </div>
                <div id='admin-connected__chevron' className={`admin-connected__${isActive ? 'rotated': 'normal'}-chevron`}>
                    <img alt='Chevron' src={Chevron}/>
                </div>
            </div>
            {
                isActive && 
                <div id='admin-connected__active'>

                    {
                        AdminConnectedEntries.map((entry) => 
                            <div id='admin-connected__entry' onClick={entry.click}>
                                <div id='admin-connected__entry-icon'>
                                    <img alt='Ícone' src={entry.icon}/>
                                </div>
                                <div id='admin-connected__entry-title'>
                                    { entry.name }
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
};