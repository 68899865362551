import React, { useEffect, useState } from "react";

import "./raffle.css";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import UserProfilePicture from "./components/userProfilePicture/userProfilePicture";
import RaffleItemCard from "./components/raffleItemCard/raffleItemCard";
import BuyTicketModal from "components/modal/buyTicketModal/buyTicketModal";
import SteamLoginModal from "components/modal/steamLoginModal/steamLoginModal";
import UserLoginModal from "components/modal/userLoginModal/userLoginModal";
import UserRegisterModal from "components/modal/userRegisterModal/userRegisterModal";

import RingImage from "assets/misc/ring.png";
import SteamIcon from "assets/icons/steamLogo.png";

import IUser from "interfaces/IUser";
import { ILevel } from "interfaces/ILevel";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import { IRaffle } from "interfaces/IRaffle";
import INormalUser from "interfaces/INormalUser";

import Logo from "assets/logo/logo-round.png";
import IWinner from "interfaces/IWinner";
import ForgotPasswordModal from "components/modal/forgotPasswordModal/forgotPasswordModal";

export default function Raffle() {
  const [renderSteamLoginModal, setRenderSteamLoginModa] =
    useState<boolean>(false);
  const [renderLoginModal, setRenderLoginModal] = useState<boolean>(false);
  const [renderRegisterModal, setRenderRegisterModal] =
    useState<boolean>(false);
  const [renderBuyTicketModal, setRenderBuyTicketModal] =
    useState<boolean>(false);
  const [renderForgotPasswordModal, setRenderForgotPasswordModal] =
    useState<boolean>(false);
  const [currentLevel, setCurrentLevel] = useState<ILevel>();
  const [currentRaffle, setCurrentRaffle] = useState<IRaffle>();
  const [userParticipants, setUserParticipants] = useState<INormalUser[]>([]);
  const [steamParticipants, setSteamParticipants] = useState<IUser[]>([]);
  const [winner, setWinner] = useState<IWinner>();

  useEffect(() => {
    APICaller.get("/raffles/lastest/")
      .then((response: AxiosResponse) => {
        const data: IRaffle = response.data.data;
        setCurrentRaffle(data);

        APICaller.get(`/levels/${data.level_id}`)
          .then((response: AxiosResponse) => {
            const levelData: ILevel = response.data.data;
            setCurrentLevel(levelData);
          })
          .catch((error) => {});

        APICaller.get(`/raffles/${data.id}/winner/`)
          .then((response: AxiosResponse) => {
            const winnerData: IWinner = response.data.data;
            setWinner(winnerData);
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (currentRaffle) {
      APICaller.get(`/raffles/${currentRaffle.level_id}/participants/`)
        .then((response: AxiosResponse) => {
          const data = response.data.data;
          setUserParticipants(data.users);
          setSteamParticipants(data.steam_users);
        })
        .catch((error) => {
          setUserParticipants([]);
          setSteamParticipants([]);
        });
    }
  }, [currentRaffle]);

  function steamLoginModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderSteamLoginModa(true);
    }
    if (value === false) {
      setRenderSteamLoginModa(false);
    }
  }

  function authModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(true);
    }
    if (value === false) {
      setRenderLoginModal(false);
    }
  }

  function registerModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderRegisterModal(true);
    }
    if (value === false) {
      setRenderRegisterModal(false);
    }
  }

  function buyTicketModalController(value: boolean) {
    if (value === true) {
      setRenderBuyTicketModal(true);
    }
    if (value === false) {
      setRenderBuyTicketModal(false);
    }
  }

  function forgotPasswordModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderForgotPasswordModal(true);
    }

    if (value === false) {
      setRenderForgotPasswordModal(false);
    }
  }

  return (
    <>
      <Header
        renderAuthScreen={authModalController}
        renderBuyTicketScreen={buyTicketModalController}
      />
      <div id="raffle-main">
        <div id="raffle-raffler">
          <div id="raffle-raffler-item-card-container">
            {currentLevel && currentRaffle && (
              <RaffleItemCard level={currentLevel} raffle={currentRaffle} />
            )}
          </div>
          {currentLevel &&
            currentRaffle &&
            currentRaffle.winner &&
            currentRaffle.winner !== "" && (
              <div id="raffle-raffler-winner-container">
                <p id="raffle-raffler-winner-label">Vencedor</p>
                <p id="raffle-raffler-date-label">Data do sorteio: { currentRaffle.updated_at }</p>
                <div id="raffle-raffler-winner-img-container">
                  <div className="raffle-raffler-winner-photo">
                    <img
                      id="raffle-raffler-winner-img"
                      src={winner?.avatar}
                      alt="Imagem do vencedor"
                      draggable={false}
                    />
                  </div>
                </div>
                <p id="raffle-raffler-winner-name-txt">{winner?.name}</p>
                <a href={winner?.url} id="raffle-raffer-winner-steam-info">
                  <img
                    id="raffle-raffler-winner-steam-img"
                    src={SteamIcon}
                    alt="Ícone social"
                    draggable={false}
                  />
                  <div id="raffle-raffler-winner-steam-label">Perfil Steam</div>
                  <p id="raffle-raffler-winner-steam-id">{winner?.social}</p>
                </a>
              </div>
            )}
        </div>
        <div id="raffle-users">
          <p id="raffle-users-title-txt">Participantes</p>
          <ul id="raffle-users-list">
            {steamParticipants.length > 0 &&
              steamParticipants.map((steam) => (
                <UserProfilePicture
                  image={steam.avatar_medium}
                  url={steam.profile_url}
                />
              ))}
            {userParticipants.length > 0 &&
              userParticipants.map((users) => (
                <UserProfilePicture image={Logo} url={users.discord} />
              ))}
          </ul>
        </div>
        <Footer />
      </div>
      {renderSteamLoginModal && (
        <SteamLoginModal
          onSteamLoginModalExitClick={steamLoginModalController}
        />
      )}
      {renderLoginModal && (
        <UserLoginModal
          onForgotPasswordModalClick={forgotPasswordModalController}
          onAuthModalExitClick={authModalController}
          onRegisterModalClick={registerModalController}
          onSteamLoginModalClick={steamLoginModalController}
        />
      )}
      {renderForgotPasswordModal && (
        <ForgotPasswordModal
          onAuthModalExitClick={authModalController}
          onForgotPasswordModalClick={forgotPasswordModalController}
        />
      )}
      {renderRegisterModal && (
        <UserRegisterModal
          onRegisterModalExitClick={registerModalController}
          onAuthModalClick={authModalController}
        />
      )}
      {renderBuyTicketModal && (
        <BuyTicketModal onBuyTicketModalExitClick={buyTicketModalController} />
      )}
    </>
  );
}
