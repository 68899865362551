import React, { useContext } from "react";

import "./marketControlSoldCard.css";

import IItem from "interfaces/IItem";
import { APICaller, imagePrefix } from "api/APICaller";
import { AxiosResponse } from "axios";
import ICustomer from "interfaces/ICustomer";
import { GlobalContext } from "context/globalProvider";

export default function MarketControlSoldCard(props: {
  item: IItem;
  customer: ICustomer | undefined;
  setShouldUpdate: Function;
}) {
  const globalContext = useContext(GlobalContext);

  function handleDeleteItem() {
    APICaller.delete(`/market/${props.item.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        props.setShouldUpdate(true);
      })
      .catch((error) => {});
  }

  function copyToClipboard() {
    if (props.customer?.trade) {
      navigator.clipboard
        .writeText(props.customer.trade)
        .then(() => {
          console.log("Texto copiado para a área de transferência.");
        })
        .catch((error) => {
          console.log("Falha ao copiar para a área de transferência.");
        });
    }
  }

  return (
    <li id="market-control-sold-card-main">
      <div id="market-control-sold-card-item">
        <div id="market-control-sold-card-item-image-container">
          <img
            id="market-control-sold-card-item-image"
            src={imagePrefix("MARKET", props.item.item_image)}
            alt=""
            draggable={false}
          />
        </div>
        <div id="market-control-sold-card-item-name">
          <p id="market-control-sold-card-item-name-txt">
            {props.item.item_name}
          </p>
        </div>
        <div id="market-control-sold-card-item-price">
          <p id="market-control-sold-card-item-price-txt">
            R$ {props.item.price}
          </p>
        </div>
      </div>
      <div id="market-control-sold-card-client">
        <div id="market-control-sold-card-client-name">
          <div className="market-control-sold-client-info-container">
            <p className="market-control-sold-client-info-txt">
              {props.customer?.name}
            </p>
          </div>
        </div>
        <div id="market-control-sold-card-client-email">
          <div className="market-control-sold-client-info-container">
            <p className="market-control-sold-client-info-txt">
              {props.customer?.email}
            </p>
          </div>
        </div>
        <div id="market-control-sold-card-client-trade-link">
          <div className="market-control-sold-client-info-container">
            <p className="market-control-sold-client-info-txt">
              {props.customer?.trade}
            </p>
          </div>
        </div>
        <div id="market-control-sold-card-client-copy-link">
          <div
            id="market-control-sold-card-client-copy-link-btn"
            onClick={copyToClipboard}
          >
            <p id="market-control-sold-card-client-copy-link-btn-txt">
              COPIAR TRADE LINK
            </p>
          </div>
        </div>
        {props.customer?.url && (
          <div id="market-control-sold-card-client-steam">
            <div id="market-control-sold-card-client-steam-btn">
              <a
                id="market-control-sold-card-client-steam-btn-txt"
                target="_blank"
                rel="noreferrer noopener"
                href={props.customer.url}
              >
                IR PARA O PERFIL DA STEAM
              </a>
            </div>
          </div>
        )}
        <div id="market-control-sold-card-client-delete">
          <div
            id="market-control-sold-card-client-delete-btn"
            onClick={handleDeleteItem}
          >
            <p id="market-control-sold-card-client-delete-btn-txt">
              EXCLUIR ITEM
            </p>
          </div>
        </div>
      </div>
    </li>
  );
}
