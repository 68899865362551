import React from "react";

import "./winnerSection.css";

import G from "assets/Gesicht.png";
import IWinner from "interfaces/IWinner";

export default function WinnerSection(props: {
  winner: IWinner | undefined;
  datetime: string;
}) {
  return (
    <div id="winner-section-main">
      <div id="winner-section-title">
        <p id="winner-section-title-txt">VENCEDOR</p>
      </div>
      <div id="winner-section-timestamp">
        <p id="winner-section-timestamp-txt">{props.datetime}</p>
      </div>
      <div id="winner-section-main-content">
        <div id="winner-section-main-content-img-container">
          <img
            id="winner-section-main-content-img"
            src={props.winner?.avatar}
            alt=""
            draggable={false}
          />
        </div>
        <div id="winner-section-main-content-data-container">
          <div className="winner-section-main-content-data">
            <p className="winner-section-main-content-data-txt">
              {props.winner?.name}
            </p>
          </div>
          <div className="winner-section-main-content-data">
            <p className="winner-section-main-content-data-txt">
              {props.winner?.social}
            </p>
          </div>
          <div id="winner-section-main-content-profile-link">
            <a
              id="winner-section-main-content-profile-link-anchor"
              href={props.winner?.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <p id="winner-section-main-content-profile-link-txt">
                IR PARA O PERFIL DA STEAM
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
