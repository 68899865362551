import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GlobalContext } from "context/globalProvider";

export default function AdminProtectedRoute(children: any) {
  const globalContext = useContext(GlobalContext);

  if (
    globalContext?.isAuthenticated === true &&
    globalContext?.isAdministrator === true
  ) {
    return <Outlet />;
  } else {
    // TODO: mudar
    return <Outlet />;
  }
}
