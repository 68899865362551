import React from "react";

import "./userProfilePicture.css";

export default function UserProfilePicture(props: {
  image: string;
  url: string;
}) {
  return (
    <li id="user-profile-picture-main">
      <a
        id="user-profile-picture-link"
        target="_blank"
        rel="noreferrer noopener"
        href={props.url}
      >
        <img
          id="user-profile-picture-img"
          src={props.image}
          alt="Imagem de usuário"
        />
      </a>
    </li>
  );
}
