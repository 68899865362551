import React, { useState, useEffect, useContext } from "react";

import "./buyTicketModalPayment.css";

import Ticket from "assets/icons/silverTicket.png";
import Add from "assets/icons/addGreen.png";
import Case from "assets/icons/case.png";
import Pix from "assets/icons/pix.png";
import MercadoPagoWallet from "components/mercadopago/mercadoPagoWallet";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";

export default function BuyTicketModalPayment(props: {
  ticketQuantity: number;
  onTicketQuantityChange: Function;
  onPaymentSelection: Function;
  onConfirm: Function;
}) {
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [firstClick, setFirstClick] = useState<boolean>(false);
  const [ticketPrice, setTicketPrice] = useState<number>(2.5);
  const [preferenceId, setPreferenceId] = useState<string>("");
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    setFirstClick(false);
  }, []);

  function handleTicketQuantityChange(value: any) {
    props.onTicketQuantityChange(value);
  }

  function handlePaymentSelection(value: string) {
    props.onPaymentSelection(value);
    setPaymentMethod(value);

    if (value === "pix") {
      APICaller.get(`/checkout/tickets/?a=${props.ticketQuantity}`, {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      })
        .then((response: AxiosResponse) => {
          const data = response.data.data;
          setPreferenceId(data);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }

  function handleConfirm() {
    props.onConfirm();
  }

  return (
    <div id="buy-ticket-modal-payment-container">
      <div id="buy-ticket-modal-payment-name-container">
        <p id="buy-ticket-modal-payment-name-txt">
          Selecione a quantidade de tickets que você quer adicionar em sua conta
        </p>
      </div>
      <div id="buy-ticket-modal-payment-quantity-container">
        <div id="buy-ticket-modal-payment-quantity">
          <div id="buy-ticket-modal-payment-quantity-img-container">
            <img
              id="buy-ticket-modal-payment-quantity-img"
              src={Ticket}
              alt=""
              draggable={false}
            />
          </div>
          <div id="buy-ticket-modal-payment-quantity-value">
            <input
              id="buy-ticket-modal-payment-quantity-value-field"
              value={props.ticketQuantity}
              onChange={(e) => {
                handleTicketQuantityChange(e.target.value);
              }}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
      <div id="buy-ticket-modal-payment-value-container">
        <div id="buy-ticket-modal-payment-value">
          <p id="buy-ticket-modal-payment-value-label-txt">Valor</p>
          <p id="buy-ticket-modal-payment-value-txt">
            R$ {(ticketPrice * props.ticketQuantity).toFixed(2).replace(".", ",")}
          </p>
        </div>
      </div>
      <div id="buy-ticket-modal-payment-option">
        <p id="buy-ticket-modal-payment-option-txt">Método de pagamento</p>
        {/* <div 
                    id="buy-ticket-modal-payment-option-selection-box" 
                    className={paymentMethod === "box"? "buy-ticket-modal-payment-option-selected ": "buy-ticket-modal-payment-option-not-selected"} 
                    onClick={() => {handlePaymentSelection("box")}}
                >
                    <div className="buy-ticket-modal-payment-option-selection-img-container">
                        <img id="buy-ticket-modal-payment-option-selection-box-img" src={Case} alt="" draggable={false}/>
                    </div>
                    <div className="buy-ticket-modal-payment-option-selection-txt-container">
                        <p className="buy-ticket-modal-payment-option-selection-txt">
                            Caixas
                        </p>
                    </div>
                </div> */}
        <div
          id="buy-ticket-modal-payment-option-selection-pix"
          className={
            paymentMethod === "pix"
              ? "buy-ticket-modal-payment-option-selected "
              : "buy-ticket-modal-payment-option-not-selected"
          }
          onClick={() => {
            handlePaymentSelection("pix");
          }}
        >
          <div className="buy-ticket-modal-payment-option-selection-img-container">
            <img
              id="buy-ticket-modal-payment-option-selection-pix-img"
              src={Pix}
              alt=""
              draggable={false}
            />
          </div>
          <div className="buy-ticket-modal-payment-option-selection-txt-container">
            <p className="buy-ticket-modal-payment-option-selection-txt">Pix</p>
          </div>
        </div>
      </div>
      <div id="buy-ticket-modal-payment-confirm">
        {/* <div id="buy-ticket-modal-payment-btn-confirm" onClick={handleConfirm}>
                    <p id="buy-ticket-modal-payment-btn-confirm-txt">
                        Prosseguir
                    </p>
                </div> */}
        {preferenceId && <MercadoPagoWallet preferenceId={preferenceId} />}
      </div>
    </div>
  );
}
