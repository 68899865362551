import React, { useState, useEffect, ChangeEvent } from "react";

import "./uploadFileBtn.css";

import DownloadIcon from "assets/icons/downloadArrow.png";

export default function UploadFileBtn(props: {
  parent?: string;
  onUploadImg: Function;
}) {
  function handleFileChange(files: any) {
    const file = files[0];
    if (file) {
      props.onUploadImg(file);
    }
  }

  function teste() {
    document.getElementById(`file-upload-field${props.parent}`)?.click();
  }

  return (
    <div id="file-upload-main">
      <input
        id={`file-upload-field${props.parent}`}
        style={{ display: "none" }}
        type="file"
        onChange={(e) => {
          handleFileChange(e.target.files);
        }}
      />
      <div id="file-upload-btn" onClick={teste}>
        <p id="file-upload-btn-txt">UPLOAD DE IMAGEM</p>
        <img id="file-upload-btn-img" src={DownloadIcon} alt="" />
      </div>
    </div>
  );

  // useEffect(() => {
  //
  // },[])

  // function handleImgUpload(){
  //
  // }

  // return (
  //     <>
  //         <form id="upload-file-btn-main">
  //             <div id="upload-file-btn-container">
  //                 <label id="upload-file-btn" htmlFor="arquivo">
  //                     <p id="upload-file-btn-text">
  //                         UPLOAD DE IMAGEM
  //                     </p>
  //                 </label>
  //                 <input type="file" name="arquivo" id="arquivo" onInputCapture={handleImgUpload}/>
  //             </div>
  //         </form>
  //     </>
  // )
}
