import React, { useContext, useEffect, useState } from "react";

import "./banner.css";

import IBanner from "interfaces/IBanner";
import TrashCan from "assets/icons/trashCan.png";
import UploadFileBtn from "components/uploadFileBtn/uploadFileBtn";
import { APICaller, imagePrefix } from "api/APICaller";
import { AxiosError, AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";

export default function Banner(props: {
  banner: IBanner | undefined;
  setBanner: Function;
  onDelete: Function;
}) {
  const [image, setImage] = useState<string>();
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    setImage(imagePrefix("BANNERS", props.banner?.filename));
  }, []);

  function handleUploadImg(img: File) {
    const form = new FormData();
    form.append("banner", img);

    setImage(URL.createObjectURL(img));

    APICaller.put(`/banners/${props.banner?.id}/`, form, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        const data: IBanner = response.data.data;
        props.setBanner(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  function deleteBanner() {
    APICaller.delete(`/banners/${props.banner?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        props.onDelete();
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  return (
    <div id="banner-main">
      <div id="banner-section-title-container">
        <p id="banner-section-title-text">BANNER</p>
      </div>
      <div id="banner-decoration" />
      <div id="banner-content-container">
        <div id="banner-content">
          <div id="banner-content-img-container">
            <img id="banner-content-img" src={image} alt="" draggable={false} />
          </div>
          <div id="banner-content-img-upload">
            <UploadFileBtn parent="banner" onUploadImg={handleUploadImg} />
          </div>
        </div>
        <div id="banner-action-btn-container">
          <div id="banner-action-btn-delete-account">
            <p className="banner-action-btn-text" onClick={deleteBanner}>
              DELETAR BANNER
            </p>
            <div className="banner-action-btn-text-img-container">
              <img className="banner-action-btn-text-img" src={TrashCan} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
