import React, { useContext, useEffect, useState } from "react";

import "./marketControlForSale.css";

import MarketControlForSaleCard from "./components/marketControlForSaleCard/marketControlForSaleCard";
import MarketControlForSaleEditItem from "./components/marketControlForSaleEditItem/marketControlForSaleEditItem";
import MarketControlForSaleAddItem from "./components/marketControlForSaleAddItem/marketControlForSaleAddItem";
import SearchField from "components/searchField/searchField";
import IItem from "interfaces/IItem";

import { APICaller } from "api/APICaller";
import { AxiosError, AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";

export default function MarketControlForSale() {
  const [renderForSaleItemEditModal, setRenderForSaleItemEditModal] =
    useState<boolean>(false);
  const [renderForSaleItemAddModal, setRenderForSaleItemAddModal] =
    useState<boolean>(false);
  const [items, setItems] = useState<IItem[]>([]);
  const [searchField, setSearchField] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<IItem[]>([]);
  const [editItem, setEditItem] = useState<IItem>();
  const [shouldRefresh, setShouldRefresh] = useState<boolean>();
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get("/market/status?query=1", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IItem[] = response.data.data;
        setItems(data);
        setFilteredItems(data);
      })
      .catch((error) => {
        setItems([]);
        setFilteredItems([]);
      });
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      APICaller.get("/market/status?query=1", {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      })
        .then((response: AxiosResponse) => {
          const data: IItem[] = response.data.data;
          setItems(data);
          setFilteredItems(data);
          setSearchField(searchField);
        })
        .catch((error) => {
          setItems([]);
          setFilteredItems([]);
        });

      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setFilteredItems(
      items.filter((item) => item.item_name.includes(searchField)),
    );
  }, [searchField]);

  function handleAddItem() {
    addForSaleItemEditController(true);
  }

  function handleEditItem(id: string) {
    const itemData = items.find((item) => {
      if (item.id === id) {
        return item;
      }
    });

    if (itemData) {
      setEditItem(itemData);
      editForSaleItemEditController(true);
    }
  }

  function addForSaleItemEditController(value: boolean) {
    if (value === true) {
      setRenderForSaleItemAddModal(true);
    }
    if (value === false) {
      setRenderForSaleItemAddModal(false);
      setShouldRefresh(true);
    }
  }

  function editForSaleItemEditController(value: boolean) {
    if (value === true) {
      setRenderForSaleItemEditModal(true);
    }
    if (value === false) {
      setRenderForSaleItemEditModal(false);
      setShouldRefresh(true);
    }
  }

  function handleSearchFieldChange(value: string) {
    setSearchField(value);
  }

  return (
    <>
      <div id="market-control-for-sale-main">
        <div id="market-control-for-sale-search">
          <div id="market-control-for-sale-search-container">
            <SearchField
              placeholder="Insira o nome do item"
              onValueChange={handleSearchFieldChange}
            />
          </div>
        </div>
        <div id="market-control-for-sale-new-item">
          <div
            id="market-control-for-sale-new-item-btn"
            onClick={handleAddItem}
          >
            <p id="market-control-for-sale-new-item-btn-txt">ADICIONAR ITEM</p>
          </div>
        </div>
        <ul id="market-control-for-sale-list">
          {filteredItems.map((item) => {
            return (
              <MarketControlForSaleCard
                key={item.id}
                data={item}
                onEditItemClick={handleEditItem}
              />
            );
          })}
        </ul>
      </div>
      {renderForSaleItemEditModal && (
        <MarketControlForSaleEditItem
          onEditForSaleItemModalExitClick={editForSaleItemEditController}
          item={editItem}
          setItem={setEditItem}
        />
      )}
      {renderForSaleItemAddModal && (
        <MarketControlForSaleAddItem
          onAddForSaleItemModalExitClick={addForSaleItemEditController}
        />
      )}
    </>
  );
}
