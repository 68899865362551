import axios, { AxiosInstance } from "axios";

const APICaller: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

APICaller.defaults.maxRedirects = 10;
APICaller.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

const imagePrefix = (imageType: string, imageName: string | undefined) =>
  `${process.env.REACT_APP_API_URL}/images/?t=${imageType.toLowerCase()}&n=${imageName}`;

export { APICaller, imagePrefix };
