import React, { useState, useEffect, useContext } from "react";

import "./market.css";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import LoginModal from "components/modal/steamLoginModal/steamLoginModal";
import SearchField from "components/searchField/searchField";
import MarketItemCard from "./components/marketItemCard/marketItemCard";
import CategoryDropdown from "./components/categoryDropdown/categoryDropdown";
import MarketPaymentModal from "./components/marketPaymentModal/marketPaymentModal";
import BuyTicketModal from "components/modal/buyTicketModal/buyTicketModal";
import SteamLoginModal from "components/modal/steamLoginModal/steamLoginModal";
import UserLoginModal from "components/modal/userLoginModal/userLoginModal";
import UserRegisterModal from "components/modal/userRegisterModal/userRegisterModal";

import IItem from "interfaces/IItem";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";
import ForgotPasswordModal from "components/modal/forgotPasswordModal/forgotPasswordModal";

export default function Market() {
  const [renderSteamLoginModal, setRenderSteamLoginModa] =
    useState<boolean>(false);
  const [renderLoginModal, setRenderLoginModal] = useState<boolean>(false);
  const [renderBuyTicketModal, setRenderBuyTicketModal] =
    useState<boolean>(false);
  const [renderMarketPaymentModal, setRenderMarketPaymentModal] =
    useState<boolean>(false);
  const [renderRegisterModal, setRenderRegisterModal] =
    useState<boolean>(false);
  const [renderForgotPasswordModal, setRenderForgotPasswordModal] =
    useState<boolean>(false);
  const [soldItem, setSoldItem] = useState<IItem>();
  const [searchField, setSearchField] = useState<string>("");

  const [itemList, setItemList] = useState<IItem[]>([]);
  const [filteredItemList, setFilteredItemList] = useState<IItem[]>([]);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get("/market/sale/")
      .then((response: AxiosResponse) => {
        const data: IItem[] = response.data.data;
        setItemList(data);
        setFilteredItemList(data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setFilteredItemList(
      itemList.filter((item) =>
        item.item_name.toLowerCase().includes(searchField.toLowerCase()),
      ),
    );
  }, [searchField]);

  function steamLoginModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderSteamLoginModa(true);
    }
    if (value === false) {
      setRenderSteamLoginModa(false);
    }
  }

  function authModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(true);
    }
    if (value === false) {
      setRenderLoginModal(false);
    }
  }

  function registerModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderRegisterModal(true);
    }
    if (value === false) {
      setRenderRegisterModal(false);
    }
  }

  function buyTicketModalController(value: boolean) {
    if (value === true) {
      setRenderBuyTicketModal(true);
    }
    if (value === false) {
      setRenderBuyTicketModal(false);
    }
  }

  function marketPaymentModalController(value: boolean) {
    if (!globalContext?.isAuthenticated) {
      setRenderLoginModal(true);
    } else {
      if (value === true) {
        setRenderMarketPaymentModal(true);
      }
      if (value === false) {
        setRenderMarketPaymentModal(false);
      }
    }
  }

  function handleItemBuy(id: string) {
    const item = itemList.find((item) => {
      if (item.id === id) {
        return item;
      }
    });

    if (item) {
      setSoldItem(item);
      marketPaymentModalController(true);
    }
  }

  function handleSearchFieldChange(value: string) {
    setSearchField(value);
  }

  function forgotPasswordModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderForgotPasswordModal(true);
    }

    if (value === false) {
      setRenderForgotPasswordModal(false);
    }
  }

  return (
    <>
      <Header
        renderAuthScreen={authModalController}
        renderBuyTicketScreen={buyTicketModalController}
      />
      <div id="market-main">
        <div id="market-list-fields">
          <div id="market-list-fields-title-container">
            <p id="market-list-fields-title-text">Mercado</p>
          </div>
          <div id="market-list-fields-input">
            <div id="market-list-fields-item-search">
              <div id="market-list-fields-item-search-container">
                <SearchField
                  placeholder="Digite o nome do item"
                  onValueChange={handleSearchFieldChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ul id="market-item-list">
          {filteredItemList.map((item) => {
            return (
              <MarketItemCard key={item.id} data={item} onBuy={handleItemBuy} />
            );
          })}
        </ul>
        <Footer />
      </div>
      {renderMarketPaymentModal && (
        <MarketPaymentModal
          onMarketPaymentModalExitClick={marketPaymentModalController}
          item={soldItem}
        />
      )}
      {renderSteamLoginModal && (
        <SteamLoginModal
          onSteamLoginModalExitClick={steamLoginModalController}
        />
      )}
      {renderLoginModal && (
        <UserLoginModal
          onForgotPasswordModalClick={forgotPasswordModalController}
          onAuthModalExitClick={authModalController}
          onRegisterModalClick={registerModalController}
          onSteamLoginModalClick={steamLoginModalController}
        />
      )}
      {renderForgotPasswordModal && (
        <ForgotPasswordModal
          onAuthModalExitClick={authModalController}
          onForgotPasswordModalClick={forgotPasswordModalController}
        />
      )}
      {renderRegisterModal && (
        <UserRegisterModal
          onRegisterModalExitClick={registerModalController}
          onAuthModalClick={authModalController}
        />
      )}
      {renderBuyTicketModal && (
        <BuyTicketModal onBuyTicketModalExitClick={buyTicketModalController} />
      )}
      <div id="market-background" />
    </>
  );
}
