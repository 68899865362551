import React, { useEffect } from "react";

import useQuery from "hooks/useQuery";
import { useNavigate } from "react-router-dom";
import { APICaller } from "api/APICaller";
import { AxiosError, AxiosResponse } from "axios";

export default function ConfirmEmail() {
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const email = query.get("email");
    const code = query.get("code");

    APICaller.put(`/auth/confirm?email=${email}`, {
      email_confirmation_code: code,
    })
      .then((response: AxiosResponse) => {
        console.log(response.data.message);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  return <div></div>;
}
