import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./header.css";

import Logo from "assets/logo/logo-with-name.png";

import AuthButton from "./components/authButton/authButton";
import AdminConnected from "./components/adminConnected/adminConnected";
import UserConnected from "./components/userConnected/userConnected";
import { GlobalContext } from "context/globalProvider";

export default function Header(props: {
  renderAuthScreen?: Function;
  renderBuyTicketScreen?: Function;
}) {
  const globalContext = useContext(GlobalContext);

  useEffect(() => {}, []);

  function handleAuthBtnClick() {
    if (props.renderAuthScreen !== undefined) {
      props.renderAuthScreen(true);
    }
  }

  function handleBuyTicketBtnClick() {
    if (props.renderBuyTicketScreen !== undefined) {
      props.renderBuyTicketScreen(true);
    }
  }

  return (
    <div id="header-main">
      <div id="header-logo-container">
        <Link id="header-logo-link" to={"/"} draggable={false}>
          <img id="header-logo" src={Logo} draggable={false} alt="" />
        </Link>
      </div>
      <div id="header-links-container">
        <div id="header-links">
          <Link className="header-link" to="/">
            <p className="header-link-text" id="x">
              Página Inicial
            </p>
          </Link>
          <Link className="header-link" to="/mercado">
            <p className="header-link-text" id="x">
              Mercado
            </p>
          </Link>
          <Link className="header-link" to="/sorteio">
            <p className="header-link-text" id="x">
              Sorteios
            </p>
          </Link>
          <Link className="header-link" to="/parceiros">
            <p className="header-link-text" id="x">
              Parceiros
            </p>
          </Link>

          {/* <Link className="header-link" to="/">
                        <p className="header-link-text" id="x">Suporte</p>
                    </Link> */}
          {/* <Link className="header-link" to="/">
                        <p className="header-link-text" id="x">Discord</p>
                    </Link> */}
          {/* <Link className="header-link" to="/sorteio-vip">
                        <p className="header-link-text" id="x">VIP</p>
                    </Link> */}
          <Link className="header-link" to="/faq">
            <p className="header-link-text" id="x">
              FAQ
            </p>
          </Link>
          <Link className="header-link" to="/termos">
            <p className="header-link-text" id="x">
              Termos e diretrizes
            </p>
          </Link>
        </div>
      </div>
      <div id="header-user-info-container">
        {globalContext?.isAdministrator && <AdminConnected/>}
        {globalContext?.isAuthenticated && (
          <UserConnected renderBuyTicketScreen={handleBuyTicketBtnClick} />
        )}
        {!globalContext?.isAuthenticated && !globalContext?.isAdministrator && (
          <AuthButton onAuthClick={handleAuthBtnClick} />
        )}
      </div>
    </div>
  );
}
