import React, { useState } from "react";

import Logo from "assets/icons/logoWithText.png";

import TextInputWithoutSave from "components/input/textInputWithoutSave/textInputWithoutSave";

import "./changePassword.css";
import { useNavigate, useParams } from "react-router-dom";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";

export default function ChangePassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  function handlePasswordChange(value: string) {
    setPassword(value);
  }

  function handleConfirmPasswordChange(value: string) {
    setConfirmPassword(value);
  }

  function handleSendButton() {
    if (password === confirmPassword) {
      APICaller.post("/auth/change-password/", {
        code: params.code,
        password: password,
      })
        .then((response: AxiosResponse) => {
          console.log(response.data.message);
          navigate("/");
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    } else {
      console.log("As senhas não coincidem.");
    }
  }

  return (
    <div id="change-password-main">
      <div id="change-password-content">
        <div id="change-password-logo-container">
          <img
            alt="Logo"
            id="change-password-logo"
            src={Logo}
            draggable={false}
          />
        </div>
        <div id="change-password-input-container">
          <div className="change-password-input">
            <TextInputWithoutSave
              label="Senha"
              onValueChange={handlePasswordChange}
              onKeyDown={() => {}}
              value={password}
              type="password"
            />
          </div>
          <div className="change-password-input">
            <TextInputWithoutSave
              label="Confirme a senha"
              onValueChange={handleConfirmPasswordChange}
              onKeyDown={() => {}}
              value={confirmPassword}
              type="password"
            />
          </div>
        </div>
        <div id="change-password-btn-container">
          <div id="change-password-btn" onClick={handleSendButton}>
            <p id="change-password-btn-text">Alterar senha</p>
          </div>
        </div>
      </div>
    </div>
  );
}
