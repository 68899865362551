import React, { useState } from "react";

import "./searchField.css";

export default function SearchField(props: {
  placeholder: string;
  onValueChange: Function;
}) {
  const [value, setValue] = useState<string>("");

  function handleValueChange(value: string) {
    setValue(value);
    props.onValueChange(value);
  }

  return (
    <div id="search-field-main">
      <input
        id="search-field-text-field"
        placeholder={props.placeholder}
        onChange={(e) => {
          handleValueChange(e.target.value);
        }}
      />
    </div>
  );
}
