import React from "react";

import "./floatBar.css";

import PointerIcon from "assets/icons/pointer.png";

export default function FloatBar(props: { float: number }) {
  function floatMargin(float: number) {
    return `${float * 100}%`;
  }

  return (
    <div id="float-main">
      <div id="float-pointer-container">
        <img
          id="float-pointer-img"
          style={{ marginLeft: floatMargin(props.float) }}
          src={PointerIcon}
          alt=""
        />
      </div>
      <div id="float-bar">
        <div id="float-bar-factory-new" />
        <div id="float-bar-minimal-wear" />
        <div id="float-bar-field-tested" />
        <div id="float-bar-well-worn" />
        <div id="float-bar-battle-scarred" />
      </div>
    </div>
  );
}
