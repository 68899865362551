import React, { useContext, useEffect, useState } from "react";

import "./marketPaymentModal.css";

import CloseCross from "assets/icons/closeCross.png";
import Pix from "assets/icons/pix.png";

import IItem from "../../../../interfaces/IItem";
import { APICaller } from "api/APICaller";
import { GlobalContext } from "context/globalProvider";
import { AxiosResponse } from "axios";
import MercadoPagoWallet from "components/mercadopago/mercadoPagoWallet";

export default function MarketPaymentModal(props: {
  item: IItem | undefined;
  onMarketPaymentModalExitClick: Function;
}) {
  function handleExitBtnClick() {
    props.onMarketPaymentModalExitClick(false);
  }

  const [preferenceId, setPreferenceId] = useState<string>("");
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get(`/checkout/market/${props.item?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: string = response.data.data;
        setPreferenceId(data);
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        setPreferenceId("");
      });
  }, []);

  return (
    <div id="market-payment-modal-main">
      <div id="market-payment-modal-content">
        <div id="market-payment-modal-exit-btn-container">
          <img
            id="market-payment-modal-exit-btn-img"
            src={CloseCross}
            alt=""
            onClick={handleExitBtnClick}
          />
        </div>
        <div id="market-payment-modal-name-container">
          <p id="market-payment-modal-name-txt">{props.item?.item_name}</p>
        </div>
        <div id="market-payment-modal-value-container">
          <div id="market-payment-modal-value">
            <p id="market-payment-modal-value-label-txt">Valor</p>
            <p id="market-payment-modal-value-txt">R$ {props.item?.price}</p>
          </div>
        </div>
        <div id="market-payment-modal-option">
          <p id="market-payment-modal-option-txt">Método de pagamento</p>
          <div id="market-payment-modal-option-selection">
            <div id="market-payment-modal-option-selection-img-container">
              <img
                id="market-payment-modal-option-selection-pix-img"
                src={Pix}
                alt=""
                draggable={false}
              />
            </div>
            <div id="market-payment-modal-option-selection-txt-container">
              <p id="market-payment-modal-option-selection-txt">Pix</p>
            </div>
          </div>
        </div>
        <div id="market-payment-modal-confirm">
          {preferenceId && <MercadoPagoWallet preferenceId={preferenceId} />}
          {/* <div id="market-payment-modal-confirm-btn">
                        
                    </div> */}
        </div>
      </div>
    </div>
  );
}
