import React, { useState, useEffect, useContext } from "react";

import "./profile.css";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import TextInputWithoutLabel from "components/input/textInputWithoutLabel/textInputWithoutLabel";
import { GlobalContext } from "context/globalProvider";
import { APICaller } from "api/APICaller";
import { Axios, AxiosResponse } from "axios";
import IEmail from "interfaces/IEmail";
import IHistory from "interfaces/IHistory";
import History from "components/history/history";
import Check from "assets/icons/check-1.png";
import Cancel from "assets/icons/cancel.png";
import BuyTicketModal from "components/modal/buyTicketModal/buyTicketModal";
import ProgressCard from "./components/progressCard";
import { IProgress } from "interfaces/IProgress";

export default function UserProfile() {
  const [discord, setDiscord] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>();
  const [tradeUrl, setTradeUrl] = useState<string>("");
  const [steamProfile, setSteamProfile] = useState<string>("");
  const [history, setHistory] = useState<IHistory[]>([]);
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [renderBuyTicketModal, setRenderBuyTicketModal] =
    useState<boolean>(false);

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get("/auth/info/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setDiscord(data.discord);
        setTradeUrl(data.trade_url);
        setSteamProfile(data.profile_url);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    APICaller.get("/emails/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IEmail = response.data.data;
        setEmail(data.address);
        setIsEmailConfirmed(data.is_confirmed);
      })
      .catch((error) => {});

    APICaller.get("/history/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IHistory[] = response.data.data;
        setHistory(data);
      })
      .catch((error) => {});

    APICaller.get("/raffles/progress/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IProgress[] = response.data.data;
        setProgress(data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (isUpdating) {
      APICaller.put(
        "/users/",
        {
          trade_url: tradeUrl,
          discord: discord,
          profile_url: steamProfile,
        },
        {
          headers: {
            Authorization: `Bearer ${globalContext?.getToken()}`,
          },
        },
      )
        .then((response: AxiosResponse) => {
          const data = response.data.data;
          console.log(response.data.message);

          setTradeUrl(data.trade_url);
          setDiscord(data.discord);
          setSteamProfile(data.profile_url);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  }, [isUpdating]);

  function buyTicketModalController(value: boolean) {
    if (value === true) {
      setRenderBuyTicketModal(true);
    }
    if (value === false) {
      setRenderBuyTicketModal(false);
    }
  }

  function handleTradeUrlChange(value: string) {
    setTradeUrl(value);
  }

  function handleEmailChange(value: string) {
    setEmail(value);
  }

  function handleDiscordChange(value: string) {
    setDiscord(value);
  }

  function handleSteamProfileChange(value: string) {
    setSteamProfile(value);
  }

  function handleTradeUrlSave(value: boolean) {
    setIsUpdating(true);
  }

  function handleEmailSave(value: boolean) {
    APICaller.put(
      "/emails/",
      {
        address: email,
      },
      {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      },
    )
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setEmail(data.address);
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  function handleDiscordSave(value: boolean) {
    setIsUpdating(true);
  }

  function handleSteamProfileSave(value: boolean) {
    setIsUpdating(true);
  }

  return (
    <>
      <Header renderBuyTicketScreen={buyTicketModalController} />
      <div id="user-account-main">
        <div id="user-account-content">
          <div id="user-account-content-title">
            <p id="user-account-content-title-text">Minha Conta</p>
          </div>
          <div id="user-account-content-container">
            <div id="user-account-primary-info" className="user-account-info">
              <div className="user-account-primary-info-input">
                <p className="user-account-primary-info-input-label">
                  URL de troca
                </p>
                <div className="user-account-info-decoration" />
                <p className="user-account-primary-info-input-instruction">
                  INSIRA AQUI SUA URL DE TROCA
                </p>
                <div className="user-account-primary-info-input-field-container">
                  <TextInputWithoutLabel
                    onValueChange={handleTradeUrlChange}
                    onSaveBtnClick={handleTradeUrlSave}
                    value={tradeUrl}
                  />
                </div>
              </div>
              <div className="user-account-primary-info-input-type2">
                <p className="user-account-primary-info-input-label">E-mail</p>
                <div className="user-account-info-decoration" />
                <div className="user-account-primary-info-input-instruction-verify">
                  <p className="user-account-primary-info-input-instruction">
                    ADICIONE UM E-MAIL PARA VERIFICAÇÃO DE CONTA
                  </p>
                  <div
                    className="user-account-primary-info-input-verify"
                    style={{
                      backgroundColor: isEmailConfirmed ? "#1B9C56" : "#D22B2B",
                    }}
                  >
                    <img
                      id="user-account-primary-info-verify-checkmark"
                      src={isEmailConfirmed ? Check : Cancel}
                      alt="Imagem da checkmark"
                    />
                    <p id="user-account-primary-info-verify-text">
                      {isEmailConfirmed ? "VERIFICADO" : "NÃO VERIFICADO"}
                    </p>
                  </div>
                </div>
                <div className="user-account-primary-info-input-field-container">
                  <TextInputWithoutLabel
                    onValueChange={handleEmailChange}
                    onSaveBtnClick={handleEmailSave}
                    value={email}
                  />
                </div>
                <p className="user-account-primary-info-input-instruction2">
                  REENVIAR E-MAIL DE CONFIRMAÇÃO
                </p>
              </div>
              <div className="user-account-primary-info-input">
                <p className="user-account-primary-info-input-label">Discord</p>
                <div className="user-account-info-decoration" />
                <div className="user-account-primary-info-input-instruction-verify">
                  <p className="user-account-primary-info-input-instruction">
                    ADICIONE O SEU ID DO DISCORD
                  </p>
                </div>
                <div className="user-account-primary-info-input-field-container">
                  <TextInputWithoutLabel
                    onValueChange={handleDiscordChange}
                    onSaveBtnClick={handleDiscordSave}
                    value={discord}
                  />
                </div>
              </div>
              <div className="user-account-primary-info-input">
                <p className="user-account-primary-info-input-label">
                  Perfil Steam
                </p>
                <div className="user-account-info-decoration" />
                <div className="user-account-primary-info-input-instruction-verify">
                  <p className="user-account-primary-info-input-instruction">
                    ADICIONE O LINK PARA O SEU PERFIL DA STEAM
                  </p>
                </div>

                <div className="user-account-primary-info-input-field-container">
                  <TextInputWithoutLabel
                    onValueChange={handleSteamProfileChange}
                    onSaveBtnClick={handleSteamProfileSave}
                    value={steamProfile}
                  />
                </div>
              </div>
              <History history={history} />
            </div>
            <div id="user-account-secondary-info" className="user-account-info">
              <div id="user-account-secondary-info-progress">
                <p className="user-account-secondary-info-label">
                  Meu Progresso
                </p>
                <div className="user-account-info-decoration" />
                <div id="user-account-secondary-info-progress-container">
                  {progress &&
                    progress.length > 0 &&
                    progress.map((prog) => <ProgressCard progress={prog} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderBuyTicketModal && (
          <BuyTicketModal
            onBuyTicketModalExitClick={buyTicketModalController}
          />
        )}
      </div>
    </>
  );
}
