import React from "react";

import IHistory, { HistoryType } from "interfaces/IHistory";

import "./historyEntry.css";
import IconPlus from "assets/icons/icon+.png";
import IconMinus from "assets/icons/icon-.png";

export default function HistoryEntry(props: { entry: IHistory }) {
  return (
    <li key={props.entry.id} id="profile-history-card-main">
      <div id="profile-history-card-main-timestamp">
        <p id="profile-history-card-main-timestamp-date">
          {props.entry.created_at}
        </p>
      </div>
      <div id="profile-history-card-main-type">
        {props.entry.type === HistoryType.WITHDRAWAL && (
          <img
            id="profile-history-card-main-type-icon"
            src={IconMinus}
            alt="Ícone de compra"
          ></img>
        )}
        {props.entry.type === HistoryType.DEPOSIT && (
          <img
            id="profile-history-card-main-type-icon"
            src={IconPlus}
            alt="Ícone de depṕsito"
          ></img>
        )}
        {props.entry.type === HistoryType.PURCHASE && (
          <div id="profile-history-card-main-type-text">
            SKIN
            <div id="profile-history-card-main-type-text-green">+</div>
          </div>
        )}
      </div>
      <div id="profile-history-card-main-description">
        <p id="profile-history-card-main-description-text">
          {props.entry.description}
        </p>
      </div>
      <div id="profile-history-card-main-value">
        <p
          id="profile-history-card-main-value-signal"
          style={{
            color:
              props.entry.type !== HistoryType.WITHDRAWAL
                ? "#5cb85c"
                : "#FF0000",
          }}
        >
          {props.entry.type !== HistoryType.WITHDRAWAL ? "+" : "-"}
        </p>
        <p id="profile-history-card-main-value-value-value">
          {props.entry.amount}
        </p>
      </div>
    </li>
  );
}
