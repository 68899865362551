import React, { useState, useContext } from "react";

import "./forgotPasswordModal.css";

import Logo from "assets/icons/logoWithText.png";
import CloseCross from "assets/icons/closeCross.png";
import TextInputWithoutSave from "../../input/textInputWithoutSave/textInputWithoutSave";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";

export default function ForgotPasswordModal(props: {
  onAuthModalExitClick: Function;
  onForgotPasswordModalClick: Function;
}) {
  const [email, setEmail] = useState<string>("");

  function handleExit() {
    props.onForgotPasswordModalClick(false);
  }

  function handleReturn() {
    props.onForgotPasswordModalClick(false);
    props.onAuthModalExitClick(true);
  }

  function handleEmail(value: string) {
    setEmail(value);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleRecoverPasswordClick();
    }
  }

  function handleRecoverPasswordClick() {
    APICaller.post("/auth/forgot/", {
      email: email,
    })
      .then((response: AxiosResponse) => {
        console.log(response.data.message);
        props.onForgotPasswordModalClick(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  return (
    <div id="forgot-password-modal-main">
      <div id="forgot-password-modal-content">
        <div id="forgot-password-modal-exit-btn-container">
          <div id="forgot-password-modal-exit-btn">
            <img
              alt="Fechar"
              id="forgot-password-modal-exit-btn-img"
              src={CloseCross}
              onClick={handleExit}
              draggable="false"
            />
          </div>
        </div>
        <div id="forgot-password-modal-logo-container">
          <img
            alt="Logo"
            id="forgot-password-modal-logo"
            src={Logo}
            draggable="false"
          />
        </div>
        <div id="forgot-password-modal-input-container">
          <div className="forgot-password-modal-input">
            <TextInputWithoutSave
              label="E-mail"
              onValueChange={handleEmail}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div id="forgot-password-modal-btn-container">
          <div
            id="forgot-password-modal-btn2"
            onClick={handleRecoverPasswordClick}
          >
            <p id="forgot-password-modal-btn-text">Recuperar senha</p>
          </div>
        </div>
        <div id="forgot-password-btn-container" onClick={handleReturn}>
          <p id="forgot-password-btn-return">Voltar</p>
        </div>
      </div>
    </div>
  );
}
