import React, { useContext, useEffect, useState } from "react";
import IUser from "interfaces/IUser";

import TextInput from "components/input/textInput/textInput";

import SilverTicket from "assets/icons/silverTicket.png";
import TrashCan from "assets/icons/trashCan.png";
import DownloadArrow from "assets/icons/downloadArrow.png";

import "./userProfileAdmin.css";
import { APICaller } from "api/APICaller";
import { Axios, AxiosResponse } from "axios";
import History from "components/history/history";
import IHistory from "interfaces/IHistory";
import { GlobalContext } from "context/globalProvider";

export default function UserProfileAdmin(props: {
  user: IUser | undefined;
  setShouldUpdateUsers: Function;
}) {
  const [tickets, setTickets] = useState<number>(0);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [tradeUrl, setTradeUrl] = useState<string>("");
  const [history, setHistory] = useState<IHistory[]>([]);
  const [wereTicketsUpdated, setWereTicketsUpdated] = useState<boolean>(false);
  const [wasEmailUpdated, setWasEmailUpdated] = useState<boolean>(false);
  const [wasTradeUrlUpdated, setWasTradeUrlUpdated] = useState<boolean>(false);

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get(`/history/${props.user?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IHistory[] = response.data.data;
        setHistory(data);
      })
      .catch((error) => {});

    APICaller.get(`/wallets/${props.user?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: number = response.data.data;
        setTickets(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    APICaller.get(`/emails/${props.user?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setEmail(data.address);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (wereTicketsUpdated) {
      updateTickets();
      setWereTicketsUpdated(false);
    }

    if (wasEmailUpdated) {
      updateEmail();
      setWasEmailUpdated(false);
    }

    if (wasTradeUrlUpdated) {
      updateTradeUrl();
      setWasTradeUrlUpdated(false);
    }
  }, [wereTicketsUpdated, wasEmailUpdated, wasTradeUrlUpdated]);

  function handleTicketChange(value: any) {
    if (/^\d+$/.test(value) === true || value === "") {
      setTickets(value);
    }
  }

  function handleNameChange(value: string) {
    setName(value);
  }

  function handleEmailChange(value: string) {
    setEmail(value);
  }

  function handleTradeUrlChange(value: string) {
    setTradeUrl(value);
  }

  function handleTicketSave() {
    setWereTicketsUpdated(true);
  }

  function handleEmailSave() {
    setWasEmailUpdated(true);
  }

  function handleTradeUrlSave() {
    setWasTradeUrlUpdated(true);
  }

  function updateTickets() {
    APICaller.put(
      `/wallets/${props.user?.id}/`,
      {
        tickets: tickets,
      },
      {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      },
    )
      .then((response: AxiosResponse) => {
        const data: number = response.data.data;
        setTickets(data);
        console.log(response.data.message);
        props.setShouldUpdateUsers(true);
      })
      .catch((error) => {});
  }

  function updateEmail() {
    APICaller.put(
      `/emails/${props.user?.id}/`,
      {
        address: email,
      },
      {
        headers: {
          Authorization: `Bearer ${globalContext?.getToken()}`,
        },
      },
    )
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setEmail(data.address);
        console.log(response.data.message);
        props.setShouldUpdateUsers(true);
      })
      .catch((error) => {});
  }

  function updateTradeUrl() {}

  function deleteAccount() {
    APICaller.delete(`/steam/${props.user?.id}`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  return (
    <div id="user-profile-admin-main">
      <div id="user-profile-admin-section-title-container">
        <p id="user-profile-admin-section-title-text">Dados do usuário</p>
      </div>
      <div id="user-profile-admin-decoration" />
      <div id="user-profile-admin-content-container">
        <div id="user-profile-admin-content">
          <div id="user-profile-admin-content-primary-info">
            <div className="user-profile-admin-content-primary-info-container">
              <TextInput
                label="Tickets"
                onValueChange={handleTicketChange}
                onSaveBtnClick={handleTicketSave}
                value={tickets}
              />
            </div>
            <div className="user-profile-admin-content-primary-info-container">
              <TextInput
                label="Nome"
                onValueChange={handleNameChange}
                onSaveBtnClick={() => {}}
                value={props.user?.persona_name}
              />
            </div>
            <div className="user-profile-admin-content-primary-info-container">
              <TextInput
                label="Email"
                onValueChange={handleEmailChange}
                onSaveBtnClick={handleEmailSave}
                value={email ?? ""}
              />
            </div>
            <div className="user-profile-admin-content-primary-info-container">
              <TextInput
                label="URL de Troca"
                onValueChange={handleTradeUrlChange}
                onSaveBtnClick={handleTradeUrlSave}
                value={props.user?.trade_url ?? ""}
              />
            </div>
          </div>
          <div id="user-profile-admin-content-secondary-info">
            <div id="user-profile-admin-content-secondary-info-tickets">
              <div id="user-profile-admin-content-secondary-info-tickets-content">
                <div id="user-profile-admin-content-secondary-info-tickets-content-img-container">
                  <img
                    id="user-profile-admin-content-secondary-info-tickets-content-img"
                    src={SilverTicket}
                    alt=""
                  />
                </div>
                <div id="user-profile-admin-content-secondary-info-tickets-content-value">
                  <p id="user-profile-admin-content-secondary-info-tickets-content-value-text">
                    {tickets}
                  </p>
                </div>
              </div>
            </div>
            <div
              id="user-profile-admin-content-history-info"
              className="user-profile-admin-content-history"
            >
              <History history={history} />
            </div>
          </div>
        </div>
        <div id="user-profile-admin-action-btn-container">
          <div
            id="user-profile-admin-action-btn-delete-account"
            onClick={deleteAccount}
          >
            <p className="user-profile-admin-action-btn-text">EXCLUIR CONTA</p>
            <div className="user-profile-admin-action-btn-text-img-container">
              <img
                className="user-profile-admin-action-btn-text-img"
                src={TrashCan}
                alt="Ícone de lixeira"
              />
            </div>
          </div>
          <div id="user-profile-admin-action-btn-download-account">
            <p className="user-profile-admin-action-btn-text">BAIXAR DADOS</p>
            <div className="user-profile-admin-action-btn-text-img-container">
              <img
                className="user-profile-admin-action-btn-text-img"
                src={DownloadArrow}
                alt="Ícone de download"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
