import React from "react";

import "./page404.css";

import Logo from "assets/logo.png";

export default function Page404() {
  return (
    <div id="page404-main">
      <div id="page404-content">
        <img id="page404-content-logo" src={Logo} alt="" draggable={false} />
        <p id="page404-content-error-txt">404</p>
        <p id="page404-content-error-txt-info">Página não encontrada</p>
      </div>
    </div>
  );
}
