import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Main from "pages/main/main";
import FAQ from "pages/faq/FAQ";
import ControlPanel from "pages/controlPanel/controlPanel";
import Market from "pages/market/market";
import Partners from "pages/partners/partners";
import Raffle from "pages/raffle/raffle";
import UserProfile from "pages/user/profile/profile";
import ConfirmEmail from "pages/confirmEmail/confirmEmail";
import Page404 from "pages/page404/page404";

import AdminProtectedRoute from "routes/adminProtectRoute";
import UserProtectedRoute from "routes/userProtectedRoute";
import ErrorPage from "pages/errorPage/errorPage";
import Terms from "pages/terms/terms";
import ChangePassword from "pages/changePassword/changePassword";

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AdminProtectedRoute />}>
          <Route element={<ControlPanel />} path="/admin/controle" />
        </Route>
        <Route element={<UserProtectedRoute />}>
          <Route element={<UserProfile />} path="/perfil" />
          <Route
            element={<ErrorPage title="503" body="Página em construção." />}
            path="/inventario"
          />
          {/* <Route element={<UserInventory/>} path="/inventario"/> */}
        </Route>
        <Route element={<ChangePassword />} path="/alterar/:code" />
        <Route element={<ConfirmEmail />} path="/confirmar" />
        <Route element={<FAQ />} path="/faq" />
        <Route element={<Terms />} path="/termos" />
        <Route element={<Main />} path="/" />
        <Route element={<Market />} path="/mercado" />
        <Route element={<Partners />} path="/parceiros" />
        <Route element={<Page404 />} path="*" />
        <Route element={<Raffle />} path="/sorteio" />
        {/* <Route element={<VipRaffle/>    } path="/sorteio-vip"/> */}
      </Routes>
    </BrowserRouter>
  );
}
