import React, { useState, useEffect } from "react";

import "./dropdown.css";
import { ItemCategory } from "interfaces/IItem";
import ChevronDown from "assets/icons/chevronDown.png";

export default function Dropdown(props: {
  selectedCategory: ItemCategory | undefined;
  setCategory: Function;
}) {
  const [width, setWidth] = useState<number>(0);

  const [renderDropDown, setRenderDropDown] = useState<boolean>(false);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [position, setPosition] = useState<any>({ positionX: 0, positionY: 0 });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setRenderDropDown(false);
  }, [windowDimensions]);

  useEffect(() => {
    const elemento = document.getElementById("market-add-item-dropdown-main");
    if (elemento != null) {
      const rect = elemento.getBoundingClientRect();
      setWidth(rect.width);
      setPosition({
        X: rect.left,
        Y: rect.top + rect.height,
      });
    }
  }, []);

  function handleRenderDropdown() {
    if (renderDropDown) {
      setRenderDropDown(false);
    } else {
      setRenderDropDown(true);
    }
  }

  function dropdownBtnStyleController() {
    if (renderDropDown) {
      return {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        // borderRight: "3px #162D46 solid",
        // borderLeft: "3px #162D46 solid",
        // borderTop: "3px #162D46 solid"
      };
    } else {
      return {
        borderBottomLeftRadius: "26px",
        borderBottomRightRadius: "26px",
      };
    }
  }

  function handleCategoryChange(value: ItemCategory) {
    props.setCategory(value);
    handleRenderDropdown();
  }

  return (
    <>
      <div id="market-add-item-dropdown-main">
        <div id="market-add-item-dropdown-label-container">
          <p id="market-add-item-dropdown-label">Categoria</p>
        </div>
        <div
          id="market-add-item-dropdown-container"
          onClick={handleRenderDropdown}
          style={dropdownBtnStyleController()}
        >
          <div id="market-add-item-dropdown-container-text">
            {props.selectedCategory === 1 ? "SKIN" : "AGENTE"}
          </div>
          <div id="market-add-item-dropdown-container-image">
            <img src={ChevronDown} alt="" />
          </div>
        </div>
      </div>
      <ul
        id="market-add-item-dropdown-list"
        style={{
          display: renderDropDown ? "flex" : "none",
          top: position.Y,
          left: position.X,
          width: width,
        }}
      >
        <li
          id="market-add-item-dropdown-item"
          key={1}
          onClick={() => handleCategoryChange(ItemCategory.SKIN)}
        >
          SKIN
        </li>
        <li
          id="market-add-item-dropdown-item"
          key={2}
          onClick={() => handleCategoryChange(ItemCategory.AGENTE)}
        >
          AGENTE
        </li>
      </ul>
    </>
  );
}
