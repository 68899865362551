import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import React, { useState, useEffect, ReactNode, createContext } from "react";
import logo from "assets/logo/logo-round.png";
import { UserRole } from "enums/Role";

interface IGlobalProvider {
  isAuthenticated: boolean;
  isAdministrator: boolean;
  username: string;
  avatar: string;
  setToken: Function;
  getToken: Function;
  rmToken: Function;
  auditToken: Function;
  getTickets: Function;
  setTickets: Function;
}
export const GlobalContext = createContext<IGlobalProvider | undefined>(
  undefined,
);

export default function GlobalProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const [username, setUsername] = useState<string>("");
  const [avatar, setAvatar] = useState<string>("");
  const [tickets, setTicketAmount] = useState<number>(0);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAdministrator, setIsAdministrator] = useState<boolean>(false);

  useEffect(() => {
    auditToken();
  }, []);

  function setToken(token: string) {
    localStorage.setItem("JWTPayload", token);
    auditToken();
  }

  function getToken() {
    const token = localStorage.getItem("JWTPayload");
    if (token) {
      return token;
    }
  }

  function rmToken() {
    localStorage.removeItem("JWTPayload");

    if (localStorage.getItem("JWTPayload") === null) {
      setIsAuthenticated(false);
      setIsAdministrator(false);
      return true;
    } else {
      return false;
    }
  }

  function auditToken() {
    const token = localStorage.getItem("JWTPayload");

    if (token === null) {
      setIsAuthenticated(false);
      setIsAdministrator(false);
    } else {
      APICaller.get("/auth/specs/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: AxiosResponse) => {
          if (response.data.role === UserRole.Admin) {
            setIsAdministrator(true);
            setIsAuthenticated(false);
          } else if (response.data.role === UserRole.Normal) {
            setIsAdministrator(false);
            setIsAuthenticated(true);
            setUsername(response.data.username);
            setAvatar(logo);
          } else if (response.data.role === UserRole.Steam) {
            setIsAdministrator(false);
            setIsAuthenticated(true);
            setUsername(response.data.username);
            setAvatar(response.data.avatar);
          } else {
            setIsAuthenticated(false);
            setIsAdministrator(false);
          }
        })
        .catch((error) => {
          setIsAuthenticated(false);
          setIsAdministrator(false);
          rmToken();
        });
    }
  }

  function getTickets() {
    const tickets = sessionStorage.getItem("_t");

    if (tickets) {
      return parseInt(tickets);
    }

    return undefined;
  }

  function setTickets(tickets: string) {
    sessionStorage.setItem("_t", tickets);
    setTicketAmount(parseInt(tickets));
  }

  return (
    <GlobalContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isAdministrator: isAdministrator,
        username: username,
        avatar: avatar,
        getTickets: getTickets,
        setTickets: setTickets,
        setToken: setToken,
        getToken: getToken,
        rmToken: rmToken,
        auditToken: auditToken,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
