import React, { useState } from "react";

import "./terms.css";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import SteamLoginModal from "components/modal/steamLoginModal/steamLoginModal";
import UserLoginModal from "components/modal/userLoginModal/userLoginModal";
import UserRegisterModal from "components/modal/userRegisterModal/userRegisterModal";
import BuyTicketModal from "components/modal/buyTicketModal/buyTicketModal";
import TermItem from "./components/termItem";
import TermData from "./terms.json";
import ForgotPasswordModal from "components/modal/forgotPasswordModal/forgotPasswordModal";

export default function Terms() {
  const [renderSteamLoginModal, setRenderSteamLoginModal] =
    useState<boolean>(false);
  const [renderLoginModal, setRenderLoginModal] = useState<boolean>(false);
  const [renderRegisterModal, setRenderRegisterModal] =
    useState<boolean>(false);
  const [renderBuyTicketModal, setRenderBuyTicketModal] =
    useState<boolean>(false);
  const [renderForgotPasswordModal, setRenderForgotPasswordModal] =
    useState<boolean>(false);

  function steamLoginModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderSteamLoginModal(true);
    }
    if (value === false) {
      setRenderSteamLoginModal(false);
    }
  }

  function authModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(true);
    }
    if (value === false) {
      setRenderLoginModal(false);
    }
  }

  function registerModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderRegisterModal(true);
    }
    if (value === false) {
      setRenderRegisterModal(false);
    }
  }

  function buyTicketModalController(value: boolean) {
    if (value === true) {
      setRenderBuyTicketModal(true);
    }
    if (value === false) {
      setRenderBuyTicketModal(false);
    }
  }

  function forgotPasswordModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderForgotPasswordModal(true);
    }

    if (value === false) {
      setRenderForgotPasswordModal(false);
    }
  }

  return (
    <>
      <Header renderAuthScreen={authModalController} renderBuyTicketScreen={buyTicketModalController}/>
      <div id="terms-page-terms">
        <div id="terms-page-title">
          <p id="terms-page-title-txt">Termos e Diretrizes</p>
        </div>
        <div id="terms-page-content">
          <div id="terms-page-content-list">
            {TermData.map((term) => (
              <TermItem
                id={term.id}
                topic={term.topic}
                content={term.content}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
      {renderSteamLoginModal && (
        <SteamLoginModal
          onSteamLoginModalExitClick={steamLoginModalController}
        />
      )}
      {renderLoginModal && (
        <UserLoginModal
          onForgotPasswordModalClick={forgotPasswordModalController}
          onAuthModalExitClick={authModalController}
          onRegisterModalClick={registerModalController}
          onSteamLoginModalClick={steamLoginModalController}
        />
      )}
      {renderForgotPasswordModal && (
        <ForgotPasswordModal
          onAuthModalExitClick={authModalController}
          onForgotPasswordModalClick={forgotPasswordModalController}
        />
      )}
      {renderRegisterModal && (
        <UserRegisterModal
          onRegisterModalExitClick={registerModalController}
          onAuthModalClick={authModalController}
        />
      )}
      {renderBuyTicketModal && (
        <BuyTicketModal onBuyTicketModalExitClick={buyTicketModalController} />
      )}
      <div id="main-background" />
    </>
  );
}
