import React, { useContext } from "react";
import { useState, useEffect } from "react";

import "./controlPanel.css";

import Header from "components/header/header";
import NavBarDropDown from "components/navBarLevelSelector/navBarLevelSelector";
import Level from "./components/level/level";
import Pricing from "./components/pricing/pricing";
import UserList from "./components/userList/userList";
import PartnerList from "./components/partnerList/partnerList";
import LevelConfiguration from "./components/levelConfiguration/levelConfiguration";
import MarketControl from "./components/market/marketControl";
import BannerConfiguration from "./components/bannerConfiguration/bannerConfiguration";
import Banner from "./components/banner/banner";
import VipRaffle from "./components/vipRaffle/vipRaffle";
import UserProfileAdmin from "./components/userProfileAdmin/userProfileAdmin";
import PartnerProfile from "./components/partnerProfile/partnerProfile";

import IUser from "interfaces/IUser";
import { IPartner, PartnerStatus } from "interfaces/IPartner";
import { ILevel } from "interfaces/ILevel";
import IBanner from "interfaces/IBanner";
import { APICaller } from "api/APICaller";
import { AxiosError, AxiosResponse } from "axios";
import NormalUserProfileAdmin from "./components/normalUserProfileAdmin/normalUserProfileAdmin";
import INormalUser from "interfaces/INormalUser";
import { GlobalContext } from "context/globalProvider";

interface INavigationController {
  [key: string]: boolean;
}

function getInitialState(): INavigationController {
  return {
    level: false,
    pricing: false,
    userList: false,
    partnerList: false,
    levelConfiguration: false,
    bannerConfiguration: false,
    banner: false,
    partnerProfile: false,
    userProfile: false,
    normalUserProfile: false,
    marketControl: false,
    vipRaffle: false,
  };
}

export default function ControlPanel() {
  const [levels, setLevels] = useState<ILevel[]>([]);
  const [level, setLevel] = useState<ILevel>();

  const [user, setUser] = useState<IUser>();
  const [steamUsers, setSteamUsers] = useState<IUser[]>([]);

  const [normalUser, setNormalUser] = useState<INormalUser>();
  const [users, setUsers] = useState<INormalUser[]>([]);
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partner, setPartner] = useState<IPartner>({
    id: "",
    name: "",
    profession: "",
    description: "",
    external_url: "",
    coupon: "",
    partner_image: "",
    expiration_date_start: "1/1/1999",
    expiration_date_end: "1/1/1999",
    status: PartnerStatus.ACTIVE,
  });

  const [banner, setBanner] = useState<IBanner>();
  const [shouldUpdateUsers, setShouldUpdateUsers] = useState<boolean>(true);

  const [navigationController, setNavigationController] =
    useState<INavigationController>(getInitialState());
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    getLevels();
  }, []);

  useEffect(() => {
    if (shouldUpdateUsers) {
      getUsers();
    }
  }, [shouldUpdateUsers]);

  useEffect(() => {
    getPartners();
  }, [partner]);

  function getLevels() {
    APICaller.get("/levels/")
      .then((response: AxiosResponse) => {
        const data: ILevel[] = response.data.data;
        setLevels(data);
      })
      .catch((error) => {
        setLevels([]);
      });
  }

  function getPartners() {
    APICaller.get("/partners/")
      .then((response: AxiosResponse) => {
        const data: IPartner[] = response.data.data;
        setPartners(data);
      })
      .catch((error) => {
        setPartners([]);
      });
  }

  function getUsers() {
    APICaller.get("/steam/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IUser[] = response.data.data;
        setSteamUsers(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    APICaller.get("/auth/", {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setUsers(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    setShouldUpdateUsers(false);
  }

  function renderElement(element: string) {
    const initialState: INavigationController = getInitialState();
    initialState[element] = true;
    setNavigationController(initialState);
  }

  function handleLevelSelection(id: string) {
    const level = levels.find((level) => level.id === id);
    if (level !== undefined) {
      setLevel(level);
    }
    renderElement("level");
  }

  function renderUserDetail(user: IUser) {
    setUser(user);
    renderElement("userProfile");
  }

  function renderNormalUserDetail(user: INormalUser) {
    setNormalUser(user);
    renderElement("normalUserProfile");
  }

  function renderPartnerDetail(partner: IPartner) {
    setPartner(partner);
    renderElement("partnerProfile");
  }

  function onDeletePartner() {
    APICaller.get("/partners/")
      .then((response: AxiosResponse) => {
        const data: IPartner[] = response.data.data;
        setPartners(data);
        renderPartnerConfiguration();
      })
      .catch((error) => {
        setPartners([]);
        renderPartnerConfiguration();
      });
  }

  function renderPartnerConfiguration() {
    renderElement("partnerList");
  }

  function renderBannerDetail(banner: IBanner) {
    setBanner(banner);
    renderElement("banner");
  }

  function renderBannerConfiguration() {
    renderElement("bannerConfiguration");
  }

  return (
    <>
      <Header />
      <div id="control-panel-main">
        <div id="control-panel-content">
          <div id="control-panel-content-section-title">
            <p id="control-panel-content-section-title-text">
              Painel de controle
            </p>
          </div>
          <div id="control-panel-content-section">
            <div id="control-panel-content-navigation-container">
              <div id="control-panel-content-navigation-bar">
                <NavBarDropDown
                  levelList={levels}
                  onLevelClick={handleLevelSelection}
                />
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("pricing");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Cotação
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("partnerList");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Parceiros
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("userList");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Usuários
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("levelConfiguration");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Gerenciar Níveis
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("marketControl");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Mercado
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("vipRaffle");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    VIP
                  </p>
                </div>
                <div
                  className="control-panel-content-navigation-bar-section"
                  onClick={() => {
                    renderElement("bannerConfiguration");
                  }}
                >
                  <p className="control-panel-content-navigation-bar-section-txt">
                    Banners
                  </p>
                </div>
              </div>
            </div>
            <div id="control-panel-content-container">
              {navigationController.level && <Level level={level} />}
              {navigationController.pricing && <Pricing />}
              {navigationController.userList && (
                <UserList
                  users={users}
                  steamUsers={steamUsers}
                  renderUserDetail={renderUserDetail}
                  renderNormalUserDetail={renderNormalUserDetail}
                />
              )}
              {navigationController.partnerList && (
                <PartnerList
                  partners={partners}
                  renderPartnerDetail={renderPartnerDetail}
                />
              )}
              {navigationController.levelConfiguration && (
                <LevelConfiguration levels={levels} setLevels={setLevels} />
              )}
              {navigationController.bannerConfiguration && (
                <BannerConfiguration renderBannerDetail={renderBannerDetail} />
              )}
              {navigationController.banner && (
                <Banner
                  banner={banner}
                  setBanner={setBanner}
                  onDelete={renderBannerConfiguration}
                />
              )}
              {navigationController.marketControl && <MarketControl />}
              {navigationController.partnerProfile && (
                <PartnerProfile
                  partner={partner}
                  setPartner={setPartner}
                  onDelete={onDeletePartner}
                />
              )}
              {navigationController.userProfile && (
                <UserProfileAdmin
                  user={user}
                  setShouldUpdateUsers={setShouldUpdateUsers}
                />
              )}
              {navigationController.normalUserProfile && (
                <NormalUserProfileAdmin
                  user={normalUser}
                  setShouldUpdateUsers={setShouldUpdateUsers}
                />
              )}
              {navigationController.vipRaffle && <VipRaffle />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
