export enum ItemStatus {
  FOR_SALE = 1,
  SOLD = 2,
}

export enum ItemCategory {
  SKIN = 1,
  AGENTE = 2,
}

export default interface IItem {
  [key: string]: string | number | undefined | ItemStatus[] | IItem;
  id?: string;
  category: ItemCategory;
  status: ItemStatus;
  item_name: string;
  item_image: string;
  price: number;
  item_float: number;
  inspection_url: string;
  buyer_id?: string;
}
