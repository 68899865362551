import React, { useState, useEffect, useContext } from "react";

import "./main.css";

import Header from "components/header/header";
import Footer from "components/footer/footer";
import JoinRaffleModal from "./components/joinRaffleModal/joinRaffleModal";
import UserLoginModal from "components/modal/userLoginModal/userLoginModal";
import UserRegisterModal from "components/modal/userRegisterModal/userRegisterModal";
import BuyTicketModal from "components/modal/buyTicketModal/buyTicketModal";
import SteamLoginModal from "components/modal/steamLoginModal/steamLoginModal";
import LevelCard from "./components/levelCard/levelCard";
import ImageSlider from "./components/imageSlider/imageSlider";

import { ILevel } from "interfaces/ILevel";

import { IRaffle, IRaffleStatus } from "interfaces/IRaffle";
import { APICaller } from "api/APICaller";
import { AxiosResponse } from "axios";
import { GlobalContext } from "context/globalProvider";
import ForgotPasswordModal from "components/modal/forgotPasswordModal/forgotPasswordModal";

export default function Main() {
  const [renderSteamLoginModal, setRenderSteamLoginModa] =
    useState<boolean>(false);
  const [renderLoginModal, setRenderLoginModal] = useState<boolean>(false);
  const [renderRegisterModal, setRenderRegisterModal] =
    useState<boolean>(false);
  const [renderBuyTicketModal, setRenderBuyTicketModal] =
    useState<boolean>(false);
  const [renderJoinRaffleModal, setRenderJoinRaffleModal] =
    useState<boolean>(false);
  const [renderForgotPasswordModal, setRenderForgotPasswordModal] =
    useState<boolean>(false);
  const [joinedRaffle, setJoinedRaffle] = useState<IRaffle>({
    id: "",
    item_name: "",
    item_float: 0,
    item_percentage: 0,
    item_svc: "",
    item_image: "",
    item_value: 0,
    boxes_earned: 0,
    level_id: "",
    status: IRaffleStatus.COMING,
    winner: "",
    updated_at: "",
  });

  const [levels, setLevels] = useState<ILevel[]>([]);
  const [raffles, setRaffles] = useState<IRaffle[]>([]);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    APICaller.get("/levels/")
      .then((response: AxiosResponse) => {
        const data: ILevel[] = response.data.data;

        APICaller.post(
          "/raffles/search/",
          data.map((l) => l.id),
        )
          .then((response: AxiosResponse) => {
            const raffleData: IRaffle[] = response.data.data;
            setRaffles(raffleData);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });

        setLevels(data);
      })
      .catch((error) => {
        setLevels([]);
      });
  }, []);

  function steamLoginModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderSteamLoginModa(true);
    }
    if (value === false) {
      setRenderSteamLoginModa(false);
    }
  }

  function authModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(true);
    }
    if (value === false) {
      setRenderLoginModal(false);
    }
  }

  function registerModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderRegisterModal(true);
    }
    if (value === false) {
      setRenderRegisterModal(false);
    }
  }

  function buyTicketModalController(value: boolean) {
    if (value === true) {
      setRenderBuyTicketModal(true);
    }
    if (value === false) {
      setRenderBuyTicketModal(false);
    }
  }

  function joinRaffleModalController(value: boolean) {
    if (!globalContext?.isAuthenticated) {
      setRenderLoginModal(true);
    } else {
      if (value === true) {
        setRenderJoinRaffleModal(true);
      }
      if (value === false) {
        setRenderJoinRaffleModal(false);
      }
    }
  }

  function handleJoinRaffle(id: string) {
    const raffle = raffles.find((raffle) => {
      if (raffle.id === id) {
        return raffle;
      }
    });

    if (raffle) {
      setJoinedRaffle(raffle);
      joinRaffleModalController(true);
    }
  }

  function forgotPasswordModalController(value: boolean) {
    if (value === true) {
      setRenderLoginModal(false);
      setRenderForgotPasswordModal(true);
    }

    if (value === false) {
      setRenderForgotPasswordModal(false);
    }
  }

  return (
    <>
      <Header
        renderAuthScreen={authModalController}
        renderBuyTicketScreen={buyTicketModalController}
      />
      
      <div id="main-page-main">
        <ul id="main-page-content">
          {levels.map((level) => {
            const raffle = raffles.find(
              (raffle) => raffle.level_id == level.id,
            );

            if (raffle) {
              return (
                <LevelCard
                  key={level.id}
                  level={level}
                  raffle={raffle}
                  onJoinRaffleClick={handleJoinRaffle}
                />
              );
            }
          })}
          <li id="main-page-slider" key={99999}>
            <ImageSlider />
          </li>
          
        </ul>
        
        <Footer />
      </div>
      {renderJoinRaffleModal && (
        <JoinRaffleModal
          onJoinRaffleModalExitClick={joinRaffleModalController}
          data={joinedRaffle}
        />
      )}
      {renderSteamLoginModal && (
        <SteamLoginModal
          onSteamLoginModalExitClick={steamLoginModalController}
        />
      )}
      {renderLoginModal && (
        <UserLoginModal
          onForgotPasswordModalClick={forgotPasswordModalController}
          onAuthModalExitClick={authModalController}
          onRegisterModalClick={registerModalController}
          onSteamLoginModalClick={steamLoginModalController}
        />
      )}
      {renderForgotPasswordModal && (
        <ForgotPasswordModal
          onAuthModalExitClick={authModalController}
          onForgotPasswordModalClick={forgotPasswordModalController}
        />
      )}
      {renderRegisterModal && (
        <UserRegisterModal
          onRegisterModalExitClick={registerModalController}
          onAuthModalClick={authModalController}
        />
      )}
      {renderBuyTicketModal && (
        <BuyTicketModal onBuyTicketModalExitClick={buyTicketModalController} />
      )}
      <div id="main-background" />
    </>
  );
}
