export enum HistoryType {
  DEPOSIT = 1,
  WITHDRAWAL = 2,
  PURCHASE = 3,
}

export default interface IHistory {
  id: string;
  created_at: string;
  type: HistoryType;
  description: number;
  amount: number;
}
