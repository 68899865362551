import React from "react";

import "./authButton.css";

import Logo from "assets/icons/steamLogo.png";

export default function AuthButton(props: { onAuthClick: Function }) {
  function handleClick() {
    props.onAuthClick(true);
  }

  return (
    <div id="auth-button-main" onClick={handleClick}>
      <div id="auth-button-image-container">
        <img id="auth-button-image" src={Logo} draggable={false} alt="" />
      </div>
      <div id="auth-button-text-container">
        <p id="auth-button-text">Iniciar sessão</p>
      </div>
    </div>
  );
}
