import React, { useContext, useEffect, useState } from "react";

import "./marketControlForSaleEditItem.css";

import TextInputWithoutSave from "components/input/textInputWithoutSave/textInputWithoutSave";
import IItem, { ItemCategory, ItemStatus } from "interfaces/IItem";
import CloseCross from "assets/icons/closeCross.png";
import UploadFileBtn from "components/uploadFileBtn/uploadFileBtn";
// import Dropdown from "./components/dropdown/dropdown"

import AK from "assets/ak.png";
import { APICaller, imagePrefix } from "api/APICaller";
import { AxiosError, AxiosResponse } from "axios";
import Dropdown from "../marketControlForSaleAddItem/components/dropdown/dropdown";
import { GlobalContext } from "context/globalProvider";

export default function MarketControlForSaleEditItem(props: {
  item: IItem | undefined;
  setItem: Function;
  onEditForSaleItemModalExitClick: Function;
}) {
  const [itemName, setItemName] = useState<string>();
  const [category, setCategory] = useState<ItemCategory>(ItemCategory.SKIN);
  const [itemFloat, setItemFloat] = useState<number>();
  const [price, setPrice] = useState<number>();
  const [inspectionUrl, setInspectionUrl] = useState<string>();

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    setItemName(props.item?.item_name);
    setItemFloat(props.item?.item_float);
    setPrice(props.item?.price);
    setInspectionUrl(props.item?.inspection_url);
  }, [props.item]);

  function handleExitBtnClick() {
    props.onEditForSaleItemModalExitClick(false);
  }

  function handleItemNameChange(value: string) {
    setItemName(value);
  }

  function handleCategoryChange(value: ItemCategory) {
    setCategory(value);
  }

  function handleItemFloatChange(value: number) {
    setItemFloat(value);
  }

  function handlePriceChange(value: number) {
    setPrice(value);
  }

  function handleInspectionUrlChange(value: string) {
    setInspectionUrl(value);
  }

  function handleSaveItem() {
    const updatedItem = {
      item_name: itemName,
      item_float: itemFloat,
      price: price,
      inspection_url: inspectionUrl,
      category: category,
    };

    APICaller.put(`/market/${props.item?.id}/`, updatedItem, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
      },
    })
      .then((response: AxiosResponse) => {
        const data: IItem = response.data.data;
        props.setItem(data);
        props.onEditForSaleItemModalExitClick(false);
      })
      .catch((error: AxiosError) => {});
  }

  function handleUploadImg(img: any) {
    const form = new FormData();
    form.append("image", img);

    APICaller.put(`/market/image/${props.item?.id}/`, form, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        const data: IItem = response.data.data;
        props.setItem(data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  function handleDeleteItem() {
    APICaller.delete(`/market/${props.item?.id}/`, {
      headers: {
        Authorization: `Bearer ${globalContext?.getToken()}`,
        "Content-Type": "application/json",
      },
    })
      .then((response: AxiosResponse) => {
        props.onEditForSaleItemModalExitClick(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }

  return (
    <div id="market-control-for-sale-edit-item-main">
      <div id="market-control-for-sale-edit-item-container">
        <div id="market-control-for-sale-edit-item-exit">
          <img
            id="join-raffle-modal-exit-btn-img"
            src={CloseCross}
            onClick={handleExitBtnClick}
            draggable={false}
            alt="Sair"
          />
        </div>
        <div id="market-control-for-sale-edit-item-img-container">
          <img
            id="market-control-for-sale-edit-item-img"
            src={imagePrefix("MARKET", props.item?.item_image)}
            alt=""
            draggable={false}
          />
        </div>
        <div id="market-control-for-sale-edit-item-edit-container">
          <div id="market-control-for-sale-edit-item-edit-image-upload">
            <UploadFileBtn
              key={"market-edit-item"}
              onUploadImg={handleUploadImg}
            />
          </div>
          <div id="market-control-for-sale-edit-item-edit-dropdown-container">
            <Dropdown
              selectedCategory={category}
              setCategory={handleCategoryChange}
            />
          </div>
          <div className="market-control-for-sale-edit-item-edit-text-input-container">
            <TextInputWithoutSave
              label="Nome do item"
              value={props.item?.item_name}
              onValueChange={handleItemNameChange}
              onKeyDown={() => {}}
            />
          </div>
          <div className="market-control-for-sale-edit-item-edit-text-input-container">
            <TextInputWithoutSave
              label="Preço"
              value={props.item?.price}
              onValueChange={handlePriceChange}
              onKeyDown={() => {}}
            />
          </div>
          {category !== ItemCategory.AGENTE && (
            <div className="market-control-for-sale-edit-item-edit-text-input-container">
              <TextInputWithoutSave
                label="Float"
                value={props.item?.item_float}
                onValueChange={handleItemFloatChange}
                onKeyDown={() => {}}
              />
            </div>
          )}
          <div className="market-control-for-sale-edit-item-edit-text-input-container">
            <TextInputWithoutSave
              label="Link de inspeção"
              value={props.item?.inspection_url}
              onValueChange={handleInspectionUrlChange}
              onKeyDown={() => {}}
            />
          </div>
        </div>
        <div id="market-control-for-sale-edit-item-btn-container">
          <div
            id="market-control-for-sale-edit-item-btn-save"
            onClick={handleSaveItem}
          >
            <p className="market-control-for-sale-edit-item-btn-txt">Salvar</p>
          </div>
          <div
            id="market-control-for-sale-edit-item-btn-delete"
            onClick={handleDeleteItem}
          >
            <p className="market-control-for-sale-edit-item-btn-txt">Excluir</p>
          </div>
        </div>
      </div>
    </div>
  );
}
