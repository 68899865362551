import React from "react";

import "./raffleItemCard.css";

import ShareIcon from "assets/icons/card-icon-share.png";
import SteamIcon from "assets/icons/card-icon-steam.png";
import FloatBar from "components/floatBar/floatBar";
import { ILevel } from "interfaces/ILevel";

import { IRaffle } from "interfaces/IRaffle";
import { imagePrefix } from "api/APICaller";

export default function RaffleItemCard(props: {
  level: ILevel;
  raffle: IRaffle;
}) {
  function floatValueToNameParser(float: number) {
    if (float >= 0.0 && float < 0.07) {
      return "Nova de fábrica";
    } else if (float >= 0.07 && float < 0.15) {
      return "Pouco Usada";
    } else if (float >= 0.15 && float < 0.38) {
      return "Testada em Campo";
    } else if (float >= 0.38 && float < 0.45) {
      return "Bem desgastada";
    } else if (float >= 0.45 && float < 1) {
      return "Veterana de Guerra";
    }
  }

  function copyToClipboard() {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        console.log("Texto copiado para a área de transferência.");
      })
      .catch((error) => {
        console.log("Falha ao copiar para a área de transferência.");
      });
  }


  return (
    <li id="raffle-level-card-main">
      <div id="raffle-level-card-label">
        <p className="raffle-level-card-label-text">NÍVEL</p>
        <p
          className="raffle-level-card-label-text"
          id="raffle-level-card-label-text-number"
        >
          {props.level.position}
        </p>
      </div>
      <div id="raffle-level-card-item">
        <div id="raffle-level-card-item-info">
          <div id="raffle-level-card-item-info-name-container">
            <p id="raffle-level-card-item-info-name-text">
              {props.raffle.item_name}
            </p>
          </div>
          <div id="raffle-level-card-item-info-aditional">
            <div id="raffle-level-card-item-info-aditional-float">
              <p id="raffle-level-card-item-info-aditional-float-name">
                {floatValueToNameParser(props.raffle.item_float)}
              </p>
              <p id="raffle-level-card-item-info-aditional-float-separator">
                |
              </p>
              <p id="raffle-level-card-item-info-aditional-float-value">
                Float: {props.raffle.item_float}
              </p>
            </div>
            <div id="raffle-level-card-item-info-aditional-float-bar-container">
              <FloatBar float={props.raffle.item_float} />
            </div>
          </div>
          <div id="raffle-level-card-item-info-value-container">
            <p id="raffle-level-card-item-info-value-label-txt">Valor:</p>
            <p id="raffle-level-card-item-info-value-txt">
              R$ {props.raffle.item_value}
            </p>
          </div>
          <div id="raffle-level-card-item-info-action-buttons">
            <a
              id="raffle-level-card-item-info-action-buttons-steam-btn"
              href={props.raffle.item_svc}
            >
              <img
                className="raffle-level-card-item-info-action-buttons-img"
                src={SteamIcon}
                alt=""
              />
            </a>
            <div id="raffle-level-card-item-info-action-buttons-share-btn" onClick={copyToClipboard}>
              <img
                className="raffle-level-card-item-info-action-buttons-img"
                src={ShareIcon}
                alt=""
              />
            </div>
          </div>
        </div>
        <div id="raffle-level-card-item-img-container">
          <img
            id="raffle-level-card-item-img"
            src={imagePrefix("RAFFLES", props.raffle.item_image)}
            alt=""
            draggable={false}
          />
        </div>
      </div>
    </li>
  );
}
