import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GlobalContext } from "context/globalProvider";

export default function UserProtectedRoute(children: any) {
  const globalContext = useContext(GlobalContext);

  if (
    globalContext?.isAuthenticated === true &&
    !globalContext.isAdministrator
  ) {
    return <Outlet />;
  } else {
    return <Outlet />;
  }
}
